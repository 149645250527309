/* @flow */

import React, { Component } from 'react';
import { Tooltip, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { reduxConnect } from '../../hoc';
import * as actions from './actions.fuelTransactionList';
import MiniDrawer from '../../components/SideMenu/SideMenuDrawer';
import ListComponent from '../../components/SharedComponents/ListComponent/ListComponent';
import {
    getPermissionValue,
    downloadFile,
    getAuthorizationHeader,
    bulkDeleteFuelTransaction,
    getUserSettingTimeZone,
    convertDateTime,
    getTimeFormatAsperUserSettting,
} from '../../helper-classes/utility-functions';
import {
    getUserPreference,
    saveUserPreference,
    getColumnPreferences,
    getReArrangedTableHeaders,
    getParsedPreferencesValue,
    rearrangeColumns,
    isUpdatePreference,
    showHideColumns,
} from '../../helper-classes/listUtil';
import AppLoader from '../../components/AppLoader';
import { RECORDS_PER_PAGE } from '../../constants/Config';
import analytics from '../../analytics/index';
import { getURL } from './epics.fuelTransactionList';
import { FUEL_TRANSACTION_LIST_PREF_KEY } from './constants.fuelTransactionList';
import Dialog from '../../components/SharedComponents/Dialog/Dialog';
import styles from './FuelTransactionList.module.scss';
import AddFuelEntry from '../AddFuelEntry/AddFuelEntry';

export type Props = {
    classes : Object,
    isUpdating: boolean,
    fetchFuelTransactionsList: Function,
    location: Object,
    history: {
        push: Function,
    },
};

export type State = {
    tableData: {
        listHeaders: Array<{show: boolean}>,
        listData: Array<{}>,
        totalRecords: number,
        ischeckBox: boolean,
    },
    rowsPerPage: number,
    pageNumber: number,
    order: string,
    orderBy: string,
    openExport: boolean,
    selectedOption: Object,
    showListing: boolean,
    isAddFuelEntry: boolean,
    loading: boolean,
    deletedErrorMessage: any,
};

class FuelTransactionList extends Component<Props, State> {
    listUrl: string;
    addUrl: string;
    userPreferences: Object;
    constructor(props) {
        super(props);
        let isAddFuelEntry = false;
        if (this.props.match.path === this.addUrl) {
            isAddFuelEntry = true;
        }
        this.listUrl = '/fuel';
        this.addUrl = '/fuel/add';
        this.state = {
            tableData: {
                listHeaders: this.getListHeader(),
                listData: [],
                totalRecords: 0,
                ischeckBox: true,
            },
            rowsPerPage: RECORDS_PER_PAGE,
            pageNumber: 0,
            order: 'desc',
            orderBy: 'transactionDate',
            openExport: false,
            selectedOption: {
                lable: 'CSV',
                value: 'csv',
                key: 'CSV',
            },
            showListing: false,
            loading: false,
            isAddFuelEntry,
            deletedErrorMessage: '',
        };
        this.userPreferences = null;
        this.button = [{
            title: 'OK',
            color: 'primary',
            variant: 'contained',
            handleClick: () => this.setState({ deletedErrorMessage: '' }),
        }];
    }

    componentDidMount() {
        if (!this.state.isAddFuelEntry) {
            getUserPreference(this, FUEL_TRANSACTION_LIST_PREF_KEY);
        }
    }

    componentDidUpdate(prevProps: any) {
        let { isAddFuelEntry } = this.state;
        if (isAddFuelEntry && this.props.match.path === this.listUrl) {
            isAddFuelEntry = false;
            this.getData();
            this.updateAddState(isAddFuelEntry);
        } else if (!isAddFuelEntry && (this.props.match.path === this.addUrl)) {
            isAddFuelEntry = true;
            this.updateAddState(isAddFuelEntry);
        }

        if (prevProps.fuelTransactions !== this.props.fuelTransactions) {
            if (this.props.fuelTransactions.length > 0) {
                const { fuelTransactions, totalFuelTransaction } = this.props;
                const { tableData } = this.state;
                const timeFormat = (getTimeFormatAsperUserSettting() === '24h') ? 'MMM D, YYYY HH:mm' : 'MMM D, YYYY hh:mm A';
                const formattedFuelTransactionList = fuelTransactions.map((item => ({
                    ...item,
                    transactionDate: item.transactionDate ? convertDateTime(item.transactionDate, timeFormat) : '',
                    state: item.location?.state,
                    dateTimeAdded: item.dateCreated ? convertDateTime(item.dateCreated, timeFormat) : '',
                    asset: item.assetRef?.name,
                })));
                tableData.listData = formattedFuelTransactionList;
                tableData.totalRecords = totalFuelTransaction;
                this.setState({ tableData });
            } else {
                const { tableData } = this.state;
                tableData.listData = [];
                tableData.totalRecords = 0;
                this.setState({ tableData });
            }
        }
    }

    updateAddState = (isAddFuelEntry) => {
        this.setState({ isAddFuelEntry });
    }

    getData = () => {
        this.props.fetchFuelTransactionsList(
            this.state.pageNumber, this.state.rowsPerPage,
            this.state.order, this.state.orderBy,
        );
        this.setState({ showListing: true });
    }

    getListHeader = () => {
        const preferences = getParsedPreferencesValue(this.userPreferences);
        const columns = [
            { transactionDate: 'Transaction Date', ...getColumnPreferences('transactionDate', preferences) },
            { state: 'State', ...getColumnPreferences('state', preferences), disableSort: true },
            { totalCost: 'Total Cost', ...getColumnPreferences('totalCost', preferences), disableSort: true },
            { totalGallons: 'Fuel Pumped', ...getColumnPreferences('totalGallons', preferences), disableSort: true },
            { costPerGallon: '$/Gallon', ...getColumnPreferences('costPerGallon', preferences), disableSort: true },
            { dateTimeAdded: 'Date Added', ...getColumnPreferences('dateTimeAdded', preferences), disableSort: true },
            { fuelType: 'Fuel Type', ...getColumnPreferences('fuelType', preferences), disableSort: true },
            {
                asset: 'Asset',
                ...getColumnPreferences('asset', preferences),
                disableSort: true,
                customMarkup: (d) => {
                    if (d.asset && d.assetId) {
                        return (
                            getPermissionValue('Assets') !== 'None' ?
                                <Tooltip title={d.asset || ''} disableFocusListener>
                                    <Link
                                        component={RouterLink}
                                        color="inherit"
                                        to={`/home?assetId=${d.assetId}&zoom=true`}
                                    >
                                        {d.asset}
                                    </Link>
                                </Tooltip>
                                : d.asset
                        );
                    }
                    return '';
                },
            },
        ];
        if (preferences) {
            return getReArrangedTableHeaders(columns);
        }
        return columns;
    }

    updateState = (order, orderBy, rowsPerPage, pageNumber) => {
        const updateUserPref = isUpdatePreference(this, { rowsPerPage, order, orderBy });
        this.setState({
            order,
            orderBy,
            rowsPerPage,
            pageNumber,
        }, () => {
            if (updateUserPref) {
                saveUserPreference(this, FUEL_TRANSACTION_LIST_PREF_KEY);
            }
        });
    }

    loadNextPage = (
        rows,
        value,
        order,
        orderBy,
    ) => {
        this.updateState(order, orderBy, value, rows);
        this.props.fetchFuelTransactionsList(
            rows,
            value,
            order,
            orderBy,
        );
    }

    sortTableColumn = (order, orderBy, page) => {
        this.props.fetchFuelTransactionsList(
            page.pageNumber,
            page.rowsPerPage,
            order,
            orderBy,
        );
        this.updateState(order, orderBy, page.rowsPerPage, page.pageNumber);
        const params = {
            feature: 'FLEET_Fuel_Transactions',
            sortColumn: orderBy,
        };
        analytics.track('FUEL_TRANSACTION_SORT', params);
    };

    refreshList = (page) => {
        this.props.fetchFuelTransactionsList(
            page.page,
            page.rowsPerPage,
            this.state.order,
            this.state.orderBy,
        );
    }

    getFuelTransactionById = (fuelTransactionId) => this.state.tableData.listData
        .find(transaction => transaction.id === fuelTransactionId) || {};

    deleteSelectedFuelTransactions = async (fuelTransactions: any) => {
        const undeletedFuelTransactions = [];

        try {
            const deletePromises = fuelTransactions.selected.map(async (fuelTransactionId) => {
                const response = await bulkDeleteFuelTransaction(fuelTransactionId);
                if (!response) {
                    return this.getFuelTransactionById(fuelTransactionId);
                }
                return null;
            });

            const results = await Promise.all(deletePromises);
            undeletedFuelTransactions.push(...results.filter(result => result !== null));

            this.refreshList(fuelTransactions);
            if (undeletedFuelTransactions.length > 0) {
                const errorMessage = (
                    <div>
                        <p>
                            The below Fuel Transactions could not be deleted:
                        </p>
                        <div>
                            <table>
                                <thead>
                                    <tr className={styles.header}>
                                        <th />
                                        <th>Asset Name</th>
                                        <th>Transaction Date</th>
                                        <th>Total Cost</th>
                                        <th>Total Gallons</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {undeletedFuelTransactions.map((fuelTransaction, index) => {
                                        const {
                                            asset,
                                            transactionDate,
                                            totalCost,
                                            totalGallons,
                                        } = fuelTransaction;
                                        return (
                                            <tr key={fuelTransaction.id}>
                                                <td>{index + 1}</td>
                                                <td>{asset}</td>
                                                <td>{transactionDate}</td>
                                                <td>{totalCost}</td>
                                                <td>{totalGallons}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
                this.setState({ deletedErrorMessage: errorMessage });
            } else {
                this.setState({ deletedErrorMessage: '' });
            }
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e.id);
        }
    }

    listTypeContextAction = (action, page = { page: 0, rowsPerPage: RECORDS_PER_PAGE }) => {
        if (action === 'refresh') {
            this.refreshList(page);
        } else if (action === 'add') {
            this.props.history.push(`${this.props.location.pathname}/add`);
        } else if (action === 'export') {
            this.openExport(true);
            analytics.track('EXPORT', { feature: 'FLEET_Fuel_Transactions' });
        } else if (action === 'delete') {
            if (page.selected.length > 0) {
                this.deleteSelectedFuelTransactions(page).then();
            }
            analytics.track('DELETE', { feature: 'FLEET_Fuel_Transactions' });
        }
        return true;
    }

    openExport = openExport => this.setState({ openExport });

    dialogHandleClick = (type, value) => {
        if (type === 'show') {
            this.setState({ selectedOption: value });
        } else if (type === 'download') {
            const { tableData, selectedOption, order, orderBy } = this.state;
            this.openExport(false);
            this.setState({ loading: true });
            let params = `limit=${tableData.totalRecords}`;
            if (order && orderBy) {
                params += `&sortBy=${orderBy}&sortOrder=${order.toUpperCase()}`;
            }
            params += `&timezone=${getUserSettingTimeZone()}`;
            const exportFields = this.getListHeader()
                .filter(field => field.show)
                .map(field => Object.keys(field)[0]);
            if (exportFields.length > 0) {
                params += `&exportFields=${exportFields.join(',')}`;
            }
            const url = `${getURL()}?${params}`;
            downloadFile(
                url,
                `Fuel Transactions ${
                    moment().format('YYYY-MM-DD HH-mm-ss')} ${order?.toUpperCase()}.${selectedOption.value}`,
                {
                    type: selectedOption.value,
                    headers: {
                        Authorization: getAuthorizationHeader(),
                        Accept: 'text/csv',
                    },
                },
                () => this.setState({ loading: false }),
            );
        } else {
            this.openExport(false);
        }
    }

    rearrangeColumns = (listHeaders) => {
        const options = { prefKey: FUEL_TRANSACTION_LIST_PREF_KEY };
        rearrangeColumns(this, listHeaders, options);
    }

    showHideColumn = (header) => {
        const options = { prefKey: FUEL_TRANSACTION_LIST_PREF_KEY };
        showHideColumns(this, header, options);
    }

    render() {
        const { loading, selectedOption, isAddFuelEntry } = this.state;
        return (
            <React.Fragment>
                {loading && <AppLoader type="fullScreen" />}
                <MiniDrawer
                    redirectTo={this.props.history.push}
                >
                    {isAddFuelEntry &&
                        <AddFuelEntry />
                    }
                    <div className={(!isAddFuelEntry) ? '' : styles.hide}>
                        {this.state.showListing ?
                            <ListComponent
                                redirectTo={this.props.history.push}
                                tableData={this.state.tableData}
                                loadNextPage={this.loadNextPage}
                                rearrangeColumns={this.rearrangeColumns}
                                isUpdating={this.props.isUpdating}
                                showHideColumn={this.showHideColumn}
                                sortTableColumn={this.sortTableColumn}
                                listTypeContextAction={this.listTypeContextAction}
                                tableTitle="Fuel"
                                showSearch={false}
                                tableButtons={['Export', 'Refresh', 'Delete', 'Add Items']}
                                resizableHeader
                                pathName={this.props.location.pathname}
                                dialogboxInfo={{
                                    title: `Export data as ${this.state.selectedOption.lable} for Fuel Transaction list`,
                                    dialogHandleClick: this.dialogHandleClick,
                                    showDialog: this.state.openExport,
                                    selectedOption,
                                    options: [{
                                        lable: 'CSV',
                                        value: 'csv',
                                        key: 'CSV',
                                    }],
                                }}
                                order={this.state.order}
                                orderBy={this.state.orderBy}
                                pageNumber={this.state.pageNumber}
                                rowsPerPage={this.state.rowsPerPage}
                            />
                            :
                            <div className={styles.loader}>
                                <AppLoader type="fullScreen" />
                            </div>
                        }
                    </div>
                </MiniDrawer>
                <Dialog
                    open={this.state.deletedErrorMessage !== ''}
                    type="error"
                    customTitle=""
                    button={this.button}
                    content={this.state.deletedErrorMessage}
                    size="lg"
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    fuelTransactions: state.fuelTransactionList.fuelTransactions,
    totalFuelTransaction: state.fuelTransactionList.totalFuelTransactions,
    isUpdating: state.fuelTransactionList.isUpdating,
    orderBy: state.orderBy,
});

export default reduxConnect(FuelTransactionList, actions, mapStateToProps);
