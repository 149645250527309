/* @flow */

import React from 'react';
import { withRouter } from 'react-router-dom';
import {
    Paper,
    FormControl,
    FormGroup,
    Divider,
    Grid,
    Button,
    Toolbar,
    Typography,
    IconButton,
    MenuItem,
    Stack,
} from '@mui/material';
import moment from 'moment';
import { ValidatorForm, SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import Dialog from '../../components/SharedComponents/Dialog/Dialog';
import backIcon from '../../assets/icons/back.png';
import {
    addFuelTransaction,
    getPermissionValue,
    getStates,
    safeParseJSON,
} from '../../helper-classes/utility-functions';
import AppLoader from '../../components/AppLoader';
import AsyncSelectWrapper from '../../components/SharedComponents/AsyncSelectWrapper';
import { getAssets } from '../../containers/Maintenance/helper-classes/common-services';
import styles from './AddFuelEntry.module.scss';

export type Props = {
    history: {
        push: Function,
    },
    responseStatus: boolean,
    responseType: string,
    responseData: string,
    closeDialog: Function,
    updateLoader: Function,
    loading: boolean,
}

export type State = {
    fuelInfo: {
        assetId: string,
        transactionDate: string,
        fuelPumped: number,
        totalCost: number,
        selectedState: string,
        fuelType: string,
    },
    openDialog: boolean,
    selectedAsset: any,
};

const defaultDataArrayParam = 'content';
const defaultDataCountParam = 'count';
const defaultDataTotalParam = 'total';
const defaultDatumLabelParam = 'name';
const defaultDatumValueParam = 'id';
const defaultPerPage = 25;
const fuelTypes = [
    { value: 'UNLEADED', label: 'Unleaded' },
    { value: 'DIESEL', label: 'Diesel' },
]

class AddFuelEntry extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            fuelInfo: {
                assetId: '',
                transactionDate: moment().format('YYYY-MM-DDTHH:mm'),
                fuelPumped: null,
                totalCost: null,
                selectedState: '',
                fuelType: '',
            },
            openDialog: false,
            fuelStates: [],
            loadingDropdown: false,
            responseStatus: false,
            responseData: null,
            loading: false,
            selectedAsset: null,
        };
    }

    componentDidMount() {
        this.setState({ loadingDropdown: true });
        this.loadDropdowns();
    }

    loadDropdowns = () => {
        const lsUrlConfig = {
            query: '',
            page: 1,
            start: 0,
            limit: 100,
        };

        getStates(lsUrlConfig)
            .then((res) => {
                if (!res || !res.success || !Array.isArray(res.data)) {
                    this.setState({ loadingDropdown: false });
                    return;
                }
                const filteredData = res.data.map(element => ({
                    id: element.id,
                    shortCode: element.shortCode,
                }));
                this.setState({ fuelStates: filteredData, loadingDropdown: false });
            })
            .catch(() => {
                this.setState({ loadingDropdown: false });
            });
    };

    handleChange = (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        this.setState({
            fuelInfo: {
                ...this.state.fuelInfo,
                [name]: name === 'fuelPumped' || name === 'totalCost' ? Number(value) : value,
            },
        });
    };

    formatError = (content, error) => {
        if (error?.errorMessage) return `${content} ${error.errorMessage}`;
        const errorDetails = safeParseJSON(error?.message);
        if (errorDetails && typeof errorDetails === 'object' && Object.keys(errorDetails).length > 0) {
            return (
                <div>
                    {content}
                    <ul>
                        {Object.entries(errorDetails).map(([key, value]) => (
                            <li key={key}>{key}: {value}</li>
                        ))}
                    </ul>
                </div>
            );
        }
        return `${content} Something went wrong`;
    };

    getDialogContent = () => {
        const { responseStatus, responseData } = this.state;
        if (responseStatus) {
            return 'Fuel entry added successfully';
        } else {
            return this.formatError('Add Fuel Entry Error:', responseData);
        }
    };

    handleDialogConfirm = () => {
        if (this.state.responseStatus) {
            this.setState({ openDialog: false });
            this.props.history.push('/fuel');
        } else {
            this.setState({ openDialog: false });
        }
    }

    handleSubmit() {
        this.setState({ loading: true });

        const { fuelInfo } = this.state;
        const formattedFuelInfo = {
            assetId: fuelInfo.assetId,
            transactionDate: new Date(fuelInfo.transactionDate).toISOString(),
            location: { state: fuelInfo.selectedState },
            totalCost: fuelInfo.totalCost,
            totalGallons: fuelInfo.fuelPumped,
            fuelType: fuelInfo.fuelType,
        };

        addFuelTransaction(formattedFuelInfo)
            .then((res) => {
                this.setState({
                    responseStatus: res.responseStatus,
                    responseData: res.errorBody,
                    openDialog: true,
                });
            })
            .catch(() => {
                this.setState({
                    responseStatus: false,
                    responseData: { errorMessage: 'Unexpected error occurred' },
                    openDialog: true,
                });
            })
            .finally(() => this.setState({ loading: false }));
    }

    handleSelect = (selectedAsset) => {
        this.setState((prevState) => ({
            fuelInfo: {
                ...prevState.fuelInfo,
                assetId: selectedAsset.value,
            },
            selectedAsset,
        }));
    };

    filterOptions = (filter: any, inputValue: any, loadedOptions: any) => (
        new Promise((resolve, reject) => {
            const perPage = filter.perPage || defaultPerPage;
            const filterParam = filter.filterParam ? [...filter.filterParam] : [];
            filter.filterOptions(loadedOptions.length, perPage, inputValue, [], filterParam)
                .then((response) => {
                    const count = response[filter.dataCountParam || defaultDataCountParam];
                    const total = response[filter.dataTotalParam || defaultDataTotalParam];
                    const hasMore = loadedOptions.length + count < total;

                    if (response) {
                        const responseData =
                            response[filter.dataArrayParam || defaultDataArrayParam];
                        if (responseData && Array.isArray(responseData)) {
                            const optionData = responseData.map(datum => ({
                                value: datum[filter.datumValueParam || defaultDatumValueParam],
                                label: datum[filter.datumLabelParam || defaultDatumLabelParam],
                                data: datum,
                            }));
                            resolve({
                                options: optionData,
                                hasMore,
                            });
                        } else {
                            reject();
                        }
                    } else {
                        reject();
                    }
                });
        }));

    render() {
        const assetFilter = {
            filterOptions: getAssets,
            filterParam: [['deviceId', 'exists:yes']],
            selectedOptions: [],
            filterType: 'select',
            filterTitle: 'Asset',
            selectPlaceholder: 'All Assets',
            property: 'assetId',
            dataArrayParam: 'data',
            datumValueParam: 'id',
            datumLabelParam: 'name',
            dataCountParam: 'count',
            propertyType: 'string',
            disableSearch: false,
        };
        const selectStyles = {
            control: () => ({
                display: 'flex',
                'border-bottom': '1px solid #d3d7db',
                boxShadow: 'none !important',
            }),
            valueContainer: style => ({ ...style, paddingLeft: '0px' }),
        };
        const { history } = this.props;
        const {
            isLoading,
            fuelInfo,
            openDialog,
            responseStatus,
            loadingDropdown,
        } = this.state;

        return (
            <React.Fragment>
                {(this.state.loading) &&<AppLoader type="fullScreen" />}
                <div className={styles.app}>
                    <Paper>
                        <Toolbar className={styles.toolbar}>
                            <span
                                className={styles.backButton}
                            >
                                <IconButton onClick={() => { history.push('/fuel'); }} size="large">
                                    <img src={backIcon} alt="backArrow" />
                                </IconButton>
                            </span>
                            <Typography variant="h6" color="inherit" className={styles.titleWrap}>
                                <span> Add Fuel Entry</span>
                            </Typography>
                        </Toolbar>
                        <ValidatorForm autoComplete="off" className={styles.form} onSubmit={() => this.handleSubmit()}>
                            <FormControl component="fieldset" className={styles.formControl}>
                                <FormGroup>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="space-between"
                                        alignItems="stretch"
                                        className={styles.mainGrid}
                                    >
                                        <Grid item xs={5}>
                                            <div
                                                className={styles.formField}
                                            >
                                                <AsyncSelectWrapper
                                                    loadOptions={(inputValue, loadedOptions) =>
                                                        this.filterOptions(
                                                            assetFilter,
                                                            inputValue,
                                                            loadedOptions,
                                                        )
                                                    }
                                                    onItemSelected={(selected) => {
                                                        this.handleSelect(selected);
                                                    }}
                                                    selectedOptions={this.state.selectedAsset}
                                                    title="Select Asset"
                                                    disableChips
                                                    showSelection
                                                    hideDivider
                                                    selectStyles={selectStyles}
                                                    styles={{ container: { flex: 1, width: '100%' } }}
                                                />
                                            </div>
                                            <Grid item xs={12} sm={6}>
                                                <TextValidator
                                                    id="Date"
                                                    name="transactionDate"
                                                    label="Transaction date/Time"
                                                    type="datetime-local"
                                                    value={fuelInfo.transactionDate}
                                                    onChange={this.handleChange}
                                                    inputFormat="y-MM-dd HH:mm"
                                                    inputProps={{
                                                        step: 300,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    className={styles.dateFeild}
                                                    validators={['required']}
                                                    errorMessages={['This field is required']}
                                                />
                                            </Grid>
                                            <Stack direction="row" alignItems="center" spacing={1} className={styles.stack}>
                                                <div className={styles.paddingTop}>
                                                    {'Fuel Pumped'}
                                                </div>
                                                <div className={styles.flexDisplay}>
                                                    <TextValidator
                                                        validators={['required']}
                                                        errorMessages={['This field is required']}
                                                        autoComplete="none"
                                                        margin="normal"
                                                        className={styles.textField}
                                                        id="fuelPumped"
                                                        name="fuelPumped"
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                min: 0,
                                                                className: styles.centerText,
                                                                onKeyDown: (e) => {
                                                                    if (['e', '+', '-', '.'].includes(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                        value={fuelInfo.fuelPumped}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <span className={styles.paddingTop}>{'(gal)'}</span>
                                            </Stack>
                                            <Stack direction="row" alignItems="center" spacing={1} className={styles.stack}>
                                                <div className={styles.paddingTop}>
                                                    {'Total cost'}
                                                </div>
                                                <div className={styles.flexDisplay}>
                                                    <TextValidator
                                                        validators={['required']}
                                                        errorMessages={['This field is required']}
                                                        margin="normal"
                                                        className={styles.textField}
                                                        id="totalCost"
                                                        name="totalCost"
                                                        type="number"
                                                        InputProps={{
                                                            inputProps: {
                                                                className: styles.centerText,
                                                                onKeyDown: (e) => {
                                                                    if (['e', '+', '-'].includes(e.key)) {
                                                                        e.preventDefault();
                                                                    }
                                                                },
                                                            },
                                                        }}
                                                        value={fuelInfo.totalCost}
                                                        onChange={this.handleChange}
                                                    />
                                                </div>
                                                <span className={styles.paddingTop}>{'($)'}</span>
                                            </Stack>
                                            <SelectValidator
                                                containerProps={{ className:
                                                    styles.validatorContainer }}
                                                value={fuelInfo.selectedState || ''}
                                                onChange={this.handleChange}
                                                className={styles.dropdown}
                                                name="selectedState"
                                                label="[Select State]"
                                                id="StateId"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            >
                                                <MenuItem value="" disabled>
                                                    [Select State]
                                                </MenuItem>

                                                {loadingDropdown ? (
                                                    <MenuItem disabled>
                                                        <div className={styles.loader}>
                                                            <AppLoader/>
                                                        </div>
                                                    </MenuItem>
                                                ) : (
                                                    this.state.fuelStates?.map(o => (
                                                        <MenuItem key={o.id} value={o.shortCode}>
                                                            {o.shortCode}
                                                        </MenuItem>
                                                    ))
                                                )}
                                            </SelectValidator>
                                            <SelectValidator
                                                containerProps={{ className:
                                                    styles.validatorContainer }}
                                                value={fuelInfo.fuelType || ''}
                                                onChange={this.handleChange}
                                                className={styles.dropdown}
                                                name="fuelType"
                                                label="Fuel Type"
                                                id="fuelType"
                                                validators={['required']}
                                                errorMessages={['This field is required']}
                                            >
                                                {fuelTypes.map((fuelType) => (
                                                    <MenuItem
                                                        key={fuelType.value}
                                                        value={fuelType.value}
                                                    >
                                                        {fuelType.label}
                                                    </MenuItem>
                                                ))}
                                            </SelectValidator>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </FormControl>
                            <Divider className={styles.divider} />
                            <Toolbar>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Button className={styles.button} onClick={() => history.push('/fuel')}>
                                            Cancel
                                        </Button>
                                        {getPermissionValue('fuel') === 'Modify' &&
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className={styles.primaryButton}
                                            type="submit"
                                            disabled={isLoading || responseStatus}
                                        >
                                            Save
                                        </Button>
                                        }
                                    </Grid>
                                </Grid>
                            </Toolbar>
                        </ValidatorForm>
                    </Paper>
                </div>
                <Dialog
                    open={openDialog}
                    type={responseStatus ? 'success' : 'error'}
                    customTitle=""
                    button={[{
                        title: 'OK',
                        color: 'primary',
                        variant: 'contained',
                        handleClick: () => this.handleDialogConfirm(),
                    }]}
                    content={this.getDialogContent()}
                    maxWidth="sm"
                />
            </React.Fragment >
        );
    }
}

export default withRouter(AddFuelEntry);
