/* @flow */
// add reducers from all containers here
import { combineReducers } from 'redux';
import home from '../containers/Home/reducers.home';
import ConfigureDashboard from '../containers/ConfigureDashboard/reducers.configureDashboard';
import userSession from '../containers/Login/reducers.login';
import globalSearch from '../containers/Header/reducers.globalsearch';
import landmarks from '../containers/Landmark/LandmarkDetails/reducers.landmarks';
import landmarkGroup from '../containers/Landmark/LandmarkGroup/reducers.landmarkGroup';
import userAccounts from '../containers/UserAccount/reducers.userAccount';
import assetList from '../containers/AssetList/reducers.assetList';
import landmarksList from '../containers/Landmark/LandmarksList/reducers.landmarksList';
import scorecardsList from '../containers/ScoreCardList/reducers.scorecardsList';
import scoredriverList from '../containers/ScoreCardDriverList/reducers.scoredriverList';
import scoreVehicleList from '../containers/ScoreCardVehicleList/reducers.scoreVehicleList';
import landmarkGroupsList from '../containers/Landmark/LandmarkGroupsList/reducers.landmarkGroupsList';
import assetGroup from '../containers/AssetGroup/reducers.assetgroup';
import address from '../containers/Address/reducers.address';
import assets from '../containers/Assets/reducers.assets';
import trips from '../containers/Trips/reducers.trips';
import spudMessage from '../containers/BannerNotification/reducers.spudNotification';
import assetGroupList from '../containers/AssetGroupList/reducers.assetGroupList';
import planList from '../containers/Maintenance/ServicePlans/reducers.planList';
import recordList from '../containers/Maintenance/ServiceRecords/reducers.recordList';
import maintenanceStatus from '../containers/Maintenance/MaintenanceStatus/reducers.maintenanceStatus';
import alertList from '../containers/Maintenance/Alerts/reducers.alertList';
import settings from '../containers/Settings/reducers.settings';
import alertNames from '../containers/Alerts/AlertLandingPage/reducers.landingPage';
import assetSearch from '../components/SharedComponents/AssetSearch/reducers.assetSearch';
import recipientList from '../containers/Alerts/AlertRecipient/reducers.alertRecipient';
import saveAlert from '../containers/Alerts/Steppers/reducers.stepers';
import alertNameCheck from '../containers/Alerts/Steppers/AlertNameCheck/reducers.alertNameCheck';
import driverScorecardTrips from '../containers/Driver/ScoreCardTrips/reducers.scoreCardTrips';
import deviceList from '../containers/DeviceList/reducers.deviceList';
import addDriver from '../containers/AddDriver/reducers.addDriver';
import driverList from '../containers/DriverList/reducers.driverList';
import fuelTransactionList from '../containers/FuelTransactionList/reducers.fuelTransactionList';
import alertHistoryList from '../containers/Alerts/AlertHistory/reducers.alertHistory';
import assetGroupManage from '../containers/AssetGroupManage/reducers.assetGroupManage';
import userList from '../containers/UserList/reducers.userList';
import userRoleList from '../containers/UserRoleList/reducers.userRoleList';
import userAccessList from '../containers/UserAccessList/reducers.userAccessList';
import alertLandmarks from '../containers/Alerts/AlertTypes/reducers.landmarks';
import savedAlertsList from '../containers/Alerts/SavedAlerts/reducers.savedAlerts';
import landmarkGroupManage from '../containers/Landmark/LandmarkGroupManage/reducers.landmarkGroupManage';
import addUser from '../containers/User/AddUser/reducers.user';
import addUserRole from '../containers/UserRole/AddUserRole/reducers.userRole';
import addLandmark from '../containers/Landmark/AddLandmark/reducers.landmark';
import recipients from '../containers/recipient/reducers.recipient';
import driverSettings from '../containers/Driver/Settings/reducers.driverSettings';
import diagnosticList from '../containers/Maintenance/Diagnostics/reducers.diagnosticList';
import nearestAssetLandmark from '../containers/NearestAssetLandmark/reducers.nearestAssetLandmark';
import dispatchAction from '../containers/Assets/DispatchAction/reducers.dispatchAction';
import sendCommand from '../containers/Assets/SendCommand/reducers.sendCommand';
import appNotifications from '../components/SharedComponents/NotificationHandler/reducers.appNotifications';
import Kml from '../containers/Kml/reducers.kml';

const appReducer = combineReducers({
    userSession,
    home,
    ConfigureDashboard,
    globalSearch,
    address,
    landmarks,
    userAccounts,
    assetGroup,
    landmarkGroup,
    settings,
    assetList,
    landmarksList,
    scorecardsList,
    scoredriverList,
    scoreVehicleList,
    landmarkGroupsList,
    assets,
    trips,
    assetGroupList,
    alertNames,
    spudMessage,
    recipientList,
    planList,
    saveAlert,
    alertNameCheck,
    deviceList,
    driverList,
    fuelTransactionList,
    addDriver,
    recordList,
    assetSearch,
    maintenanceStatus,
    alertList,
    driverScorecardTrips,
    alertHistoryList,
    assetGroupManage,
    userList,
    userRoleList,
    userAccessList,
    alertLandmarks,
    savedAlertsList,
    landmarkGroupManage,
    addUserRole,
    addLandmark,
    recipients,
    addUser,
    driverSettings,
    nearestAssetLandmark,
    diagnosticList,
    dispatchAction,
    sendCommand,
    appNotifications,
    Kml,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === 'USERS_LOGOUT') {
        return appReducer({}, action);
    }
    return appReducer(state, action);
};

export default rootReducer;
