/* @flow */

import { combineEpics } from 'redux-observable';
import { FETCH_FUEL_TRANSACTIONS_LIST } from './constants.fuelTransactionList';
import config from '../../constants/Config';
import { fetchFuelTransactionsListSuccess, fetchFuelTransactionsListError } from './actions.fuelTransactionList';

type ObservablesTypes = {
    getJSON: Function,
    of: Function
}

export const getURL = () => {
    const url = `${config.get('FLEET_SERVICES_URL')}/api/fuel-transactions`;
    return url;
};

export const getFuelTransactions =
    (actions$: Function, store: Object, { getJSON, of }: ObservablesTypes) =>
        actions$
            .ofType(FETCH_FUEL_TRANSACTIONS_LIST)
            .mergeMap((action) => {
                const storeData = store.getState();
                const headers = {
                    'X-Nspire-UserToken': storeData.userSession.userToken,
                };
                const {
                    order,
                    orderBy,
                    pageNumber,
                    pageSize,
                } = action.payload;
                let params = `limit=${pageSize}&offset=${pageNumber * pageSize}`;
                if (order && orderBy) {
                    params += `&sortBy=${orderBy}&sortOrder=${order.toUpperCase()}`;
                }
                return getJSON(`${getURL()}?${params}`, headers)
                    .map(result => fetchFuelTransactionsListSuccess(result.content, result.total))
                    .catch(error => of(fetchFuelTransactionsListError(error)));
            });


export default combineEpics(getFuelTransactions);
