/* @flow */

import { FETCH_FUEL_TRANSACTIONS_LIST, FETCH_FUEL_TRANSACTIONS_LIST_ERROR, FETCH_FUEL_TRANSACTIONS_LIST_SUCCESS } from './constants.fuelTransactionList';
import type { FuelTransactionListTypeAction } from './constants.fuelTransactionList';


export const fetchFuelTransactionsList = (
    pageNumber: number,
    pageSize: number,
    order: string,
    orderBy: string,
): FuelTransactionListTypeAction => ({
    type: FETCH_FUEL_TRANSACTIONS_LIST,
    payload: {
        pageNumber,
        pageSize,
        order,
        orderBy,
    },
});

export const fetchFuelTransactionsListSuccess =
    (fuelTransactions: any, totalFuelTransactions: number): FuelTransactionListTypeAction => ({
        type: FETCH_FUEL_TRANSACTIONS_LIST_SUCCESS,
        payload: { fuelTransactions, totalFuelTransactions },
    });

export const fetchFuelTransactionsListError = (error: string): FuelTransactionListTypeAction => ({
    type: FETCH_FUEL_TRANSACTIONS_LIST_ERROR,
    payload: { error },
});
