/* @flow */

import { FETCH_FUEL_TRANSACTIONS_LIST_ERROR, FETCH_FUEL_TRANSACTIONS_LIST_SUCCESS, FETCH_FUEL_TRANSACTIONS_LIST } from './constants.fuelTransactionList';

const initialState = {
    fuelTransactions: [],
    isUpdating: false,
};

type stateType = {
    fuelTransactions: Array<Object>,
    isUpdating: boolean,
}
type actionType = {
    type: string,
    payload :{
        fuelTransactions:{},
        totalFuelTransactions: number
    }
}

export default (state:stateType = initialState, action:actionType) => {
    switch (action.type) {
    case FETCH_FUEL_TRANSACTIONS_LIST:
        return {
            ...state,
            isUpdating: true,
        };
    case FETCH_FUEL_TRANSACTIONS_LIST_SUCCESS:
        return {
            ...state,
            isUpdating: false,
            fuelTransactions: action.payload.fuelTransactions,
            totalFuelTransactions: action.payload.totalFuelTransactions,
        };
    case FETCH_FUEL_TRANSACTIONS_LIST_ERROR:
        return {
            ...state,
            isUpdating: false,
        };
    default:
        return state;
    }
};
