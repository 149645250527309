/* eslint no-prototype-builtins: 'error' */
/* eslint-disable */
/* @flow */
import axios from 'axios';
import moment from 'moment-timezone';
import Cookies from 'js-cookie';
import queryString from 'query-string';
import config, { TRIP_DIRECTIONS_CACHE_LIMIT, NON_REPORTING_DAYS, FIELD_MAX_LENGTH_255, PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from '../constants/Config';
import download from './download';
import analytics from '../analytics';
import { SPIREON_COOKIE_DOMAIN , PRIVATE_LABEL_COOKIE_DOMAIN, NSPIRE_USER_TOKEN_COOKIE_NAME } from '../containers/Login/contants.login';
import { settingData } from '../components/Driver/constants.settings';
import { CONSTANTS } from '../constants/constants';
import { SCORECARD_PREF_KEY } from '../containers/ScoreCardDriverList/constants.scoredriverList';
let tripDataStore = {
    eventCount: 0,
};

export const isEmpty = (data: any) => {
    let dataIsEmpty = true;
    if (!data) return dataIsEmpty;
    if (Array.isArray(data)) {
        dataIsEmpty = data.length === 0;
    } else {
    // because Object.keys(new Date()).length === 0;
    // we have to do some additional check
    dataIsEmpty = Object.keys(data).length === 0 && data.constructor === Object;
    }
    return dataIsEmpty;
}

export const MItoKMratio = 1.609344;
export const listTypePermissionMap = {
    Home: { permissionKeys: 'localFleet-map', landingPage: '/home' },
    Assets: { permissionKey: 'localFleet-map', landingPage: '/assets' },
    Fleet: { permissionKey: 'localFleet-map', landingPage: '/assets' },
    Landmarks: { permissionKey: 'localFleet-map', landingPage: '/landmarks' },
    'Asset Groups': { permissionKey: 'localFleet-admin', landingPage: '/asset-groups' },
    'Landmark Groups': { permissionKey: 'localFleet-admin', landingPage: '/landmark-groups' },
    Devices: { permissionKey: 'localFleet-admin', landingPage: '/devices' },
    Drivers: { permissionKey: 'localFleet-admin', landingPage: '/drivers' },
    fuel: { permissionKey: 'localFleet-map', landingPage: '/fuel' },

    Maintenance: { permissionKey: 'localFleet-maintenance', landingPage: '/status' },
    'Maintenance Status': { permissionKey: 'localFleet-maintenance', landingPage: '/status' },
    'Maintenance Alerts': { permissionKey: 'localFleet-maintenance', landingPage: '/maintenance-alerts' },
    'Service Plans': { permissionKey: 'localFleet-maintenance', landingPage: '/status' },
    'Service Records': { permissionKey: 'localFleet-maintenance', landingPage: '/status' },

    Reports: { permissionKey: 'localFleet-reports', landingPage: '/reports' },

    'Driver Behavior': { permissionKey: 'localFleet-driver-behavior', landingPage: CONSTANTS.DRIVER_SCORECARD },
    'Driver Scorecard': { permissionKey: 'localFleet-driver-behavior', landingPage: CONSTANTS.DRIVER_SCORECARD },
    'Driver Setup': { permissionKey: 'localFleet-driver-behavior', landingPage: '/driver-setup' },

    Dashcam: { permissionKey: 'localFleet-dashcam', landingPage: '/dashcam' },
    LightMetricsDashcam: { permissionKey: 'localFleet-dashcam', landingPage: '/lm-dashcam' },

    Alerts: { permissionKey: 'localFleet-alerts', landingPage: '/alert-types' },
    'Saved Alerts': { permissionKey: 'localFleet-alerts', landingPage: '/alert-types' },
    'Alert History': { permissionKey: 'localFleet-alerts', landingPage: '/alert-types' },

    Admin: { permissionKey: 'localFleet-admin', landingPage: '/users' },
    Users: { permissionKey: 'localFleet-admin', landingPage: '/users' },
    'User Roles': { permissionKey: 'localFleet-admin', landingPage: '/users' },
    'User Access': { permissionKey: 'localFleet-admin', landingPage: '/users' },
    Recipients: { permissionKey: 'localFleet-admin', landingPage: '/users' },
    'Import History': { permissionKey: 'localFleet-admin', landingPage: '/users' },

    Compliance: { permissionKey: 'localFleet-eld', landingPage: '/summary' },
    Summary: { permissionKey: 'localFleet-eld', landingPage: '/summary' },
    Logs: { permissionKey: 'localFleet-eld', landingPage: '/logs' },
    Driver: { permissionKey: 'localFleet-eld', landingPage: '/driver-status' },
    Inspections: { permissionKey: 'localFleet-eld', landingPage: '/inspections' },
    'Repair Work Orders': { permissionKey: 'localFleet-eld', landingPage: 'workorders/list' },
    'Policies & Procedures': { permissionKey: 'localFleet-eld', landingPage: '/policy' },
    Dashboard: {permissionKey: 'localFleet-dashboard', landingPage: '/dashboard'},
    'General User': { permissionKey: 'localFleet-general-user', landingPage: '/settings' },
    };

const getAccountFeatureKey = (key) => {
    let value = '';
    switch(key) {
    case 'fleetEld': value = 'fleet.Eld';
        break;
    case 'fleetMaintenanceDtc': value = 'fleet.MaintenanceDtc';
        break;
    case 'fleetDriverId': value = 'fleet.DriverId';
        break;
        case 'fleetWexFuelCard': value = 'fleet.WexFuelCard';
        break;
    case 'fleetFleetCorFuelCard': value = 'fleet.FleetCorFuelCard';
        break;
    case 'fleetFuelCard': value = 'fleet.FuelCard';
        break;
    case 'fleetQuarlesFuelCard': value = 'fleet.QuarlesFuelCard';
        break;
    case 'fleetTemperature': value = 'fleet.Temperature';
        break;
    case 'fleetEldWorkOrders': value = 'fleet.EldWorkOrders';
        break;
    case 'fleetDashcam': value = 'fleet.Dashcam';
        break;
    case 'fleetLightMetricsDashcam': value = 'fleet.LightMetricsDashcam';
        break;
    default: value = key;
    };
    return value;
};

let grailsRefId = '';
let currentAccountUserId = '';
let userIsImpersonated = '';
let currentAccountGlobalId = '';
let appUserSettings = JSON.parse('{}');
let deviceFeature = {};
let accountFeatures = [];
let userToken = '';
let resellerInfo = {};
let termsAndConditions = {};

export function setLocalStorageItem(key: string, value: string) {
    localStorage.setItem(key, value);
}

export function getLocalStorageValue(key: string) {
    return localStorage.getItem(key) || '';
}

export function removeLocalStorageItem(key: string) {
    localStorage.removeItem(key);
}

export function clearLocalStorage() {
    localStorage.clear();
}

export const setSessionStorageItem = (key: string, value: string) => {
    if (key && value && typeof key === 'string' && typeof value === 'string') {
        sessionStorage.setItem(key, value);
    }
    return false;
}

export const getSessionStorageItem = (key: string) => {
    if (key && typeof key === 'string') {
        return sessionStorage.getItem(key) || '';
    }
    return '';
}

export const removeSessionStorageItem = (key: string) => {
    if (key && typeof key === 'string') {
        sessionStorage.removeItem(key);
    }
    return false;
}

export const clearSessionStorage = () => {
    sessionStorage.clear();
}

export const isImpersonatedUser = () => {
    if (userIsImpersonated === '') {
        userIsImpersonated = getLocalStorageValue('fleet-impersonated') || '';
    }
    return userIsImpersonated || 'false';
}

export function getCurrentAccountUserId() {
    if (currentAccountUserId === '') {
        currentAccountUserId = getLocalStorageValue('currentAccountUserId') || '';
    }
    return currentAccountUserId;
}


export function getCurrentAccountGlobalId() {
    if (currentAccountGlobalId === '') {
        currentAccountGlobalId = getLocalStorageValue('currentAccountGlobalId') || '';
    }
    return currentAccountGlobalId;
}

export function getAppUserSettings(settingName: string = '') {
    if (isEmpty(appUserSettings)) {
        appUserSettings = JSON.parse(getLocalStorageValue('userSettings') || '{}');
        removeLocalStorageItem('userSettings');
    }
    return (settingName !== '') ? appUserSettings[settingName] : appUserSettings;
}

export function updateAppUserSettings(userSettings: any) {
    if (!isEmpty(userSettings)) {
        const appSettings = userSettings['app.settings'] && userSettings['app.settings'].value ?
            userSettings['app.settings'].value : {};
        if (!isEmpty(appSettings)) {
            userSettings.tzOffset = appSettings.tzOffset ? appSettings.tzOffset : '';
            userSettings.tzDST = appSettings.tzDST ? appSettings.tzDST : '';
            userSettings.tz = appSettings.tz ? appSettings.tz : '';
            userSettings.timeFormat = appSettings.timeDisplay ? appSettings.timeDisplay : '';
        }
        userSettings.units = userSettings.userUnits ? userSettings.userUnits : '';
        userSettings.userLabel = `${userSettings.username}: ${userSettings.lName}, ${userSettings.fName}`;
        userSettings.fullName = `${userSettings.fName} ${userSettings.lName}`;
        setLocalStorageItem('username', userSettings.username);
        setLocalStorageItem('email', userSettings.email);
        setLocalStorageItem('userid', userSettings.userId);
        appUserSettings = userSettings;
    }
}

export function getDeviceFeatures() {
    if (isEmpty(deviceFeature)) deviceFeature = {};
    return deviceFeature;
}

export const updateDeviceFeatures = (newDeviceFeature: any) => {
    if (newDeviceFeature) deviceFeature = newDeviceFeature;
}

export const getAccountFeatures = () => {
    if (isEmpty(accountFeatures)) accountFeatures = {};
    return accountFeatures;
}

export const updateAccountFeatures = (newAccountFeatures: any) => {
    if (newAccountFeatures) accountFeatures = newAccountFeatures;
}

export const updateAccountSettings = (data: any) => {
    if (data) {
        if (data.accountFeatures) updateAccountFeatures(data.accountFeatures);
        if (data.assetFeatures) updateDeviceFeatures(data.assetFeatures);
    }
}

export const getBearerToken = (token: string) => `Bearer ${token}`;

export const getAuthorizationHeader = (token: string = '') => getBearerToken(getUserToken());

export function getUserToken() {
    if (userToken === '') userToken = getLocalStorageValue('userToken') || '';
    return userToken;
}

export const setUserToken = (userToken: string) => {
    setLocalStorageItem('userToken', userToken);
    setCookie(userToken, NSPIRE_USER_TOKEN_COOKIE_NAME);
}

export const clearCookieOnLogout = () => {
    Cookies.remove(NSPIRE_USER_TOKEN_COOKIE_NAME, { domain: getAllowedCookieDomain() });
}

export function getUsername() {
    const { username = getLocalStorageValue('username') || '' } = getAppUserSettings();
    return username || '';
}

/**
 * This function will update all the global variables
 * Especially if we switch user account, this will reset al the new userSettings value.
 */
export function updateGlobalVariables() {
    currentAccountUserId = getLocalStorageValue('currentAccountUserId') || '';
    appUserSettings = JSON.parse(getLocalStorageValue('userSettings') || '{}');
    deviceFeature = JSON.parse(getLocalStorageValue('deviceFeature') || '{}');
    accountFeatures = JSON.parse(getLocalStorageValue('accountFeatures') || '[]');
    userToken = getLocalStorageValue('userToken') || '';
    currentAccountGlobalId = getLocalStorageValue('currentAccountGlobalId') || '';
}

export function clearGlobalVariables() {
    grailsRefId = '';
    currentAccountUserId = '';
    appUserSettings = JSON.parse('{}');
    deviceFeature = {};
    accountFeatures = [];
    userToken = '';
    resellerInfo = {};
    termsAndConditions = {};
    tripDataStore = {
        eventCount: 0,
    };
    currentAccountGlobalId = '';
}

const isJSON = (str) => {
    try {
        return (JSON.parse(str || '') && !!str);
    } catch (e) {
        return false;
    }
};

export function localStorageGet(
    key: string,
    callback: ({ username: string, remember: boolean }) => void,
) {
    const item = (getLocalStorageValue(key): ?string);
    let result;
    if (!item) return;

    if (item[0] === '{' || item[0] === '[') {
        result = (JSON.parse(item) || null);
    }
    callback(result || { username: '', remember: false });
}

export function getIframeUserToken() {
    if (getLocalStorageValue('iframeUserToken')
    && getLocalStorageValue('iframeUserToken') !== ''
    ) {
        return getLocalStorageValue('iframeUserToken');
    }
    return getUserToken();
}

export function getPermissionValue(key: string, data: any = {}) {
    const userSettings = (data && data.userSettings) ? data.userSettings : getAppUserSettings();
    let permission = 'None';
    const permissionKey = (listTypePermissionMap[key] && listTypePermissionMap[key].permissionKey) || 'localFleet-map';
    if (permissionKey && !isEmpty(userSettings)) {
        const permissions = userSettings.permissions || [];

        if (userSettings.masterUser) {
            permission = 'Modify';
        } else if (permissionKey === 'localFleet-general-user') {
            permission = 'Show';
        } else if (permissions.length > 0) {
            for (let i = 0; i < permissions.length; i++) {
                if (permissions[i].permissionCode === permissionKey) {
                    permission = permissions[i].option;
                    break;
                }
            }
        }
    }
    if (permission !== 'None') {
        if (key === 'Compliance') permission = !showCompliance() ? 'None' : permission;
        else if (key === 'Dashcam') permission = hasFeature('fleetDashcam') ? permission : 'None';
        else if (key === 'LightMetricsDashcam') permission = hasFeature('fleetLightMetricsDashcam') ? permission : 'None';
        else if (key === 'Repair Work Orders') permission = !showCompliance() ? 'None' : hasFeature('fleetEldWorkOrders') ? permission : 'None';
    }
    return permission;
}

export const showCompliance = () => {
    const userSettings = getAppUserSettings()
    const { securityUniverses, permissions, masterUser } = userSettings;

    if (securityUniverses !== undefined && securityUniverses.length > 0) {
        const hasUserAccess = securityUniverses.some(item => item.name === 'Full Access');

        if (hasUserAccess && hasFeature('fleetEld'))  {
            if (masterUser) return true;
            else if (permissions && permissions.length > 0) {
                return permissions.some(item => (item.option === 'Manager' || item.option === 'View') && item.permissionCode === 'localFleet-eld');
            }
        }
    }

    return false;
};

export function getDefaultLandingPage() {
    let userSettings = getAppUserSettings();

    if (!isEmpty(userSettings)) {
        if (userSettings['app.settings'] &&
            userSettings['app.settings'].value &&
            userSettings['app.settings'].value.defaultPage) {
            let pageName = userSettings['app.settings'].value.defaultPage;
            pageName = pageName.toLowerCase().split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join();
            if (getPermissionValue(pageName) !== 'None') return userSettings['app.settings'].value.defaultPage;
        }

        userSettings = userSettings.permissions || [];
        userSettings.sort((a, b) => (a.id > b.id) ? 1 : -1);
        if (typeof userSettings === 'object') {
            for (let i = 0; i < userSettings.length; i++) {
                if (userSettings[i].option !== 'None') {
                    let landingPageSection = userSettings[i].permissionName.replace(' Section', '');
                    if (landingPageSection === 'Dashcam' && hasFeature('fleetLightMetricsDashcam')) {
                        landingPageSection = 'LightMetricsDashcam';
                    }
                    // for Compliance tab we need to check getPermissionValue
                    if (listTypePermissionMap[landingPageSection] && getPermissionValue(landingPageSection) !== 'None') {
                        if (listTypePermissionMap[landingPageSection].landingPage === CONSTANTS.DRIVER_SCORECARD) {
                            let driverPrefPath = getLocalStorageValue('driverPreference');
                            driverPrefPath = driverPrefPath ? driverPrefPath : 'driver-scorecard/vehicles';
                            removeLocalStorageItem('driverPreference')
                            return '/' + driverPrefPath.replace(/"/g, '');
                        }
                        return listTypePermissionMap[landingPageSection].landingPage;
                    }
                }
            }
        }
    }
    return listTypePermissionMap['General User'].landingPage;
}

export const getDefaultLandingPageURLWithSlash = () => {
    let defaultLandingPage = getDefaultLandingPage();
    defaultLandingPage = defaultLandingPage.trim();

    if (defaultLandingPage.charAt(0) !== '/') {
        defaultLandingPage = `/${defaultLandingPage}`;
    }

    return defaultLandingPage;
}

export function trackAnalytics(eventName: string) {
    const userSettings = getAppUserSettings();
    analytics.track(eventName, {
        accountId: userSettings.accountId,
        accountName: userSettings.accountName,
    })
}

export function getMylocalTimeDiff(utcTime: string) {
    return moment.utc(utcTime).local().format('h [hours] m [min]');
}

export function convertDateInGivenFormat(utcTime: string, dateFormat: string) {
    return moment.utc(utcTime).local().format(dateFormat);
}

export function getPastDate(date: string, pastDateDifference: number, pastDateUnit: string) {
    return moment(date).subtract(pastDateDifference, pastDateUnit);
}

export const getCurrentPosition = () => new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
});

export const loadPosition = async () => {
    try {
        const position = await getCurrentPosition();
        const { latitude, longitude } = position.coords;
        return { latitude, longitude };
    } catch (error) {
    }
    return { latitude: 0, longitude: 0 };
};

export const isValidEmail = (email: string) => {
    if (email.length === 0 || email.indexOf('@') === -1 || email.lastIndexOf('.') < email.indexOf('@')) {
        return false;
    }
    return true;
};

export const isValidPhone = (phone: string) => {
    if (phone.length === 0 || Number.isInteger(Number(+phone)) === false) {
        return false;
    }
    return true;
};

export const arrayMove = (array: any, oldIndex: number, newIndex: number) => {
    let newInx = newIndex;
    if (newIndex >= array.length) {
        newInx = array.length - 1;
    }
    array.splice(newInx, 0, array.splice(oldIndex, 1)[0]);
    return array;
}

export const getFormattedTimeDifference = (milliSecond: number) => {
    let milliSec = milliSecond;

    const daysDifference = Math.floor(milliSec / 1000 / 60 / 60 / 24);
    milliSec -= daysDifference * 1000 * 60 * 60 * 24;

    const hoursDifference = Math.floor(milliSec / 1000 / 60 / 60);
    milliSec -= hoursDifference * 1000 * 60 * 60;

    const minutesDifference = Math.floor(milliSec / 1000 / 60);
    milliSec -= minutesDifference * 1000 * 60;

    if (daysDifference === 0 && hoursDifference === 0 && minutesDifference === 0) return '<1m';
    return `${daysDifference > 0 ? `${daysDifference}d` : ''} ${hoursDifference > 0 ? `${hoursDifference}h`: ''} ${minutesDifference > 0 ? `${minutesDifference}m` : ''}`;
}

export const timeDifference = (date1: any, date2: any) => {
    const difference = date1.getTime() - date2.getTime();
    return getFormattedTimeDifference(difference);
};

export const shortTimeDifference = (date1: any, date2: any) => {
    const diff = timeDifference(date1, date2);
    return diff ? diff.trim().split(' ').slice(0, 2).join(' ') : diff;
}

export const daysDifference = (date1: string, date2: string) => {
    const moment1 = moment(date1);
    const moment2 = moment(date2);
    const daysDiff = moment1.diff(moment2, 'days');
    const duration = moment.duration(moment1.diff(moment2)).asDays();
    if (duration === 0) return 0;
    if (daysDiff !== 0) {
        const hrsDiff = Math.abs(moment1.diff(moment2, 'hours'));
        return daysDiff < 0 && hrsDiff > 0 ? daysDiff - 1 : daysDiff + 1;
    }
    return duration < 0 ? -1 : duration;
};

export const changeDateFormat = (dt: string, formatDest: string) => {
    const thisMoment = moment(dt);
    return thisMoment.format(formatDest);
};

export const getISODateTrunc = (date: string) => moment.utc(date).format('YYYY-MM-DD[T]HH:mm:ss');

export const getParamValue = (props: Object, key: string) => (props.match.params[key]);

export const convertKM2MI = (distance: number) => distance * (1 / MItoKMratio);

export const convertMI2KM = (distance: number) => distance * MItoKMratio;

export const getUnitType = (unitName: string) => {
    switch (unitName) {
    case 'english':
    case 'mi':
    case 'miles':
        return 'english';
    case 'metric':
    case 'km':
    case 'meters':
        return 'metric';
    default:
        return unitName;
    }
};

export const getUnitsOfMeasure = () => {
    const unitsOfMeasure = [
        {
            name: 'English',
            value: 'miles',
            rateAbbreviation: 'mph',
            abbreviation: 'mi',
        },
        {
            name: 'Metric',
            value: 'meters',
            rateAbbreviation: 'kph',
            abbreviation: 'km',
        },
    ];
    return unitsOfMeasure;
};

export const getUserUnit = () => {
    const userSettings = getAppUserSettings();
    if (userSettings) {
        let userUnits = userSettings.userUnits;
        if (userSettings['app.settings'] && userSettings['app.settings'].value.units) {
            userUnits = userSettings['app.settings'].value.units;
        }
        return getUnitType(userUnits);
    }
    return '';
}

export const getCorrectDistanceRounded = (distance: number, originalUnits: string) => {
    return Math.round(getCorrectDistance(distance, originalUnits));
};

export const getCorrectDistance = (distance: number, originalUnits: string) => {
    // originalUnits is a lowercase string of the incoming unit (km,mi)
    // getCorrectDistance returns in km or mi depending on the users settings
    const userUnit = getUserUnit();
    if (originalUnits === 'km' && userUnit === 'english') {
        return convertKM2MI(distance);
    } else if (originalUnits === 'mi' && userUnit === 'metric') {
        return convertMI2KM(distance);
    }
    return distance;
};

export const getDistanceInUnitType = (distance: number, targetUnit: string) => {
    const userUnit = getUserUnit();
    if (userUnit === 'english' && targetUnit === 'metric') {
        return convertMI2KM(distance);
    } else if (userUnit === 'metric' && targetUnit === 'english') {
        return convertKM2MI(distance);
    }
    return distance;
};

export const convertBackToMeters = (distance:number) => {
    // converts from an already converted value back into meters
    // for posting form values to api
    return getUserUnit() === 'metric' ? distance * 1000 :  convertMI2KM(distance) * 1000;
}

export const getDistanceString = () => {
    return getUserUnit() === 'metric' ? 'km' : 'mi';
};

export const getSpeedUnitString = () => {
    return getUserUnit() === 'metric' ? 'kph' : 'mph';
};

export const getDistanceStringLong = () => {
    return getUserUnit() === 'metric' ? 'Kilometers' : 'Miles';
}

export function convertSpeedAsperUserSetting(speed: string) {
    return getUserUnit() === 'metric' ? `${Number(speed) * 1.60934} kph` : `${speed} mph`;
}

export function convertUnitValueAsPerUserSetting(unitValue: number, unit: string = '') {
    const convertedValue = getUserUnit() === 'metric' ? Number(unitValue) * 1.60934 : unitValue;
    if (unit === 'round') {
        return Math.round(convertedValue);
    } else if (unit === 'ceil') {
        return Math.ceil(convertedValue);
    }
    return convertedValue;
}

export function roundOffUnitValueAsPerUserSetting(unitValue: number) {
    return Math.ceil(convertUnitValueAsPerUserSetting(unitValue));
}

export function reverseUnitValAsPerUserSetting(unitValue: number, unit: string = '') {
    const convertedValue = getUserUnit() === 'metric' ? Number(unitValue)/1.60934 : unitValue;
    if (unit === 'round') {
        return Math.round(convertedValue);
    } else if (unit === 'ceil') {
        return Math.ceil(convertedValue);
    }
    return convertedValue;
}

export function getDistanceToDisplay(distance : number) {
    if (!distance && distance !== 0) {
        return '';
    }
    let remainingDist = getCorrectDistance(Math.abs(distance), 'mi').toFixed(1);
    if (remainingDist.endsWith('.0')) remainingDist = remainingDist.substring(0, remainingDist.length - 2);
    return remainingDist;
}

export const getTemperatureUnitString = () => {
    return getUserUnit() === 'metric' ? 'C' :  'F';
};

export function convertTempAsPerUserSettings(unitValue: number) {
    return getUserUnit() === 'metric' ? (Number(unitValue) - 32) * .5556 :  unitValue;
}

export function getRoundOffTemp(unitValue: any) {
    return Math.round(convertTempAsPerUserSettings(parseFloat(unitValue)));
}

export function revTempAsPerUserSettings(unitValue: any) {
    return getUserUnit() === 'metric' ? Math.round((Number(unitValue) *.5556) + 32) :  unitValue;
}

export const getTimeZones = () => {
    const timeZones = [
        {
            name: 'Hawaiian Time',
            value: 'Pacific/Honolulu',
            offset: -10 * 3600,
            dst: 0,
        },
        {
            name: 'Alaska Time',
            value: 'America/Anchorage',
            offset: -9 * 3600,
            dst: 1,
        },
        {
            name: 'Pacific No DST Time',
            value: 'Pacific/Pitcairn',
            offset: -8 * 3600,
            dst: 0,
        },
        {
            name: 'Pacific Time',
            value: 'America/Los_Angeles',
            offset: -8 * 3600,
            dst: 1,
        },
        {
            name: 'Mountain No DST Time',
            value: 'America/Phoenix',
            offset: -7 * 3600,
            dst: 0,
        },
        {
            name: 'Mountain Time',
            value: 'America/Denver',
            offset: -7 * 3600,
            dst: 1,
        },
        {
            name: 'Central No DST Time',
            value: 'America/Regina',
            offset: -6 * 3600,
            dst: 0,
        },
        {
            name: 'Central Time',
            value: 'America/Chicago',
            offset: -6 * 3600,
            dst: 1,
        },
        {
            name: 'Eastern No DST Time',
            value: 'America/Cancun',
            offset: -5 * 3600,
            dst: 0,
        },
        {
            name: 'Eastern Time',
            value: 'America/New_York',
            offset: -5 * 3600,
            dst: 1,
        },
        {
            name: 'Atlantic Time',
            value: 'America/Halifax',
            offset: -4 * 3600,
            dst: 0,
        },
    ];
    return timeZones;
};

export function convertDateTimeAsperUserSetting(utcTime: string, format: string) {
    const userSettings = getAppUserSettings();
    const appSettings = userSettings['app.settings'] || {};
    if (appSettings.value && appSettings.value.tzOffset) {
        const offset = appSettings.value.tzOffset / 60;
        return moment.utc(utcTime).utcOffset(offset).format(format);
    }
    return convertDateInGivenFormat(utcTime, format);
}

export function getTimeFormatAsperUserSettting() {
    const userSettings = getAppUserSettings();
    let timeFormat = null;
    if (userSettings && userSettings['app.settings'] && userSettings['app.settings'].value) {
        timeFormat = userSettings['app.settings'].value.timeDisplay;
    }
    return timeFormat || '12h';
}

export function timeDrivenFormatter(elapsed: number) {
    if (elapsed === null) {
        return '';
    }
    const minutes = Math.floor(elapsed / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (hours >= 24) {
        return `${days} d ${hours % 24} h ${minutes % 60} m`
    }
    if (minutes >= 60) {
        return `${hours} h ${minutes % 60} m`
    }
    if (minutes < 1 && elapsed > 0) {
        return '<1 m'
    }
    return `${minutes} m`
}

export function formatData(data: any) {
    if (!data && data.length <= 0) return [];
    return data.map((d) => {
        const record = Object.assign({}, d);

        if (d.attributes) {
            record.attributes = JSON.stringify(d.attributes).replace(/\{|\}/g, '').replace(/,/g, ', ');
        }
        return record;
    });
}

export function getEngineHoursToDisplay(hours : number) {
    if (hours === null) {
        return '';
    }
    let hrs = Math.abs(hours).toFixed(1);
    if (hrs.endsWith('.0')) hrs = hrs.substring(0, hrs.length - 2);
    return hrs;
}

export const convertCamelCaseToString = (str: string = '') => str.replace(/\b\S/g, t => t.toUpperCase());

export const hasFeature = (featureCodeKey: string) => {
    let accountFeature = getAccountFeatures();
    const featureCode = getAccountFeatureKey(featureCodeKey);
    if (isEmpty(accountFeatures)) accountFeature = {};

    if (accountFeature !== undefined && accountFeature.length > 0) {
        return accountFeature.some(item => item === featureCode);
    }
    return false;
};

export const hasAnyFeature = (featureCodeKeys: string[]) => {
    return featureCodeKeys.some(featureCodeKey => hasFeature(featureCodeKey));
};

export const assetDataFilterBasedOnDeviceFeature = (key: string, assetFeatures?: Object) => {
    let deviceFeatureData = getDeviceFeatures();
    if (isEmpty(deviceFeatureData)) {
        deviceFeatureData = {};
    }
    if (key === 'temperature' && hasFeature('fleetTemperature')) {
        return true;
    }
    let isValid = false;
    switch (key) {
        case 'seatBeltStatus': {
            if (deviceFeatureData && (deviceFeatureData.hasSeatBelt)) { // accountLevelCheck
                isValid = true;
            }
            if (assetFeatures && !assetFeatures.seatBeltEnabled) { // asset Level Check
                isValid = false;
            }
            break;
        }
        case 'fuelCard': {
            isValid = hasFeature('fleetFuelCard') || hasFeature('fleetFleetCorFuelCard') || hasFeature('fleetWexFuelCard');
            break;
        }
        case 'oilLifeRemaining':
            if (deviceFeatureData && deviceFeatureData.hasOilLife) {//accountLevelCheck
                isValid = true;
            }
            if (assetFeatures && !assetFeatures.oilLifeEnabled) { // asset Level Check
                isValid = false;
            }
            break;
        case 'tirePressure': {
            if (deviceFeatureData && deviceFeatureData.hasTirePressure) {//accountLevelCheck
                isValid = true;
            }
            if (assetFeatures && !assetFeatures.tirePressureEnabled) { // asset Level Check
                isValid = false;
            }
            break;
        }
        case 'fuelLevelStatus': {
            if (deviceFeatureData && deviceFeatureData.hasFuelLevel) { //accountLevelCheck
                isValid = true;
            }
            if (assetFeatures && !assetFeatures.fuelLevelEnabled) { // asset Level Check
                isValid = false;
            }
            break;
        }
        case 'fridgeStatus' : {
            // account and asset level check for asset details page only
            if (hasFeature('fleetTemperature') && assetFeatures && assetFeatures.fridgeStatusEnabled) {
                isValid = true;
            }
            break;
        }
        default:
            break;
    }
    return isValid;
};

export function getLocalFleetAsset(assetId: string) {
    const options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    const offset = 0;
    const limit = 25;
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetAssetService/getAssets`;
    url = `${url}?_dc=${randVal}&globalId=${encodeURI(assetId)}`;

    return fetch(url, options).then(response => response.json());
}

export function getFuelCardAccounts() {
    const options = {
        headers: {
            'Accept-Encoding': 'gzip, deflate',
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetFuelCardService/getFuelCardAccounts`;
    url = `${url}?_dc=${randVal}&page=1&start=0&limit=50`;

    return fetch(url, options).then(response => response.json());
}

export function getInstallationRecord(assetId: string) {
    const options = {
        headers: {
            'Authorization': getAuthorizationHeader(),
            'Content-Type': 'application/json',
        },
    };
    let url = `${config.get('INSTALLATION_SERVICES_URL')}/installations`;
    url = `${url}?scope=ACCOUNT&assetId=${assetId}&limit=1`;
    return fetch(url, options).then(response => response.json());
}

export const getInstallationType = (installType: any, harnessType: any) => {
    if(installType === 'Data Harness' && harnessType !== '') {
        return `${installType} - ${harnessType}`;
    }
    return installType;
};

export function getWexFuelCardAccounts() {
    const options = {
        headers: {
            'Accept-Encoding': 'gzip, deflate',
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetWexFuelCardService/getFuelCardAccounts`;
    url = `${url}?_dc=${randVal}&page=1&start=0&limit=50`;

    return fetch(url, options).then(response => response.json());
}

export function getFleetCorFuelCardAccounts() {
    const options = {
        headers: {
            'Accept-Encoding': 'gzip, deflate',
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetFleetCorFuelCardService/getFuelCardAccounts`;
    url = `${url}?_dc=${randVal}&page=1&start=0&limit=50`;

    return fetch(url, options).then(response => response.json());
}

export function getFuelCards(accountId: string, query: string = '') {
    const options = {
        headers: {
            'Accept-Encoding': 'gzip, deflate',
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetFuelCardService/getUnassignedFuelCards`;
    url = `${url}?_dc=${randVal}&fcAccount=${accountId}&query=${query}&page=1&start=0&limit=50`;

    return fetch(url, options).then(response => response.json());
}

export function getWexFuelCards(accountId: string, query: string = '') {
    const options = {
        headers: {
            'Accept-Encoding': 'gzip, deflate',
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetWexFuelCardService/getUnassignedFuelCards`;
    url = `${url}?_dc=${randVal}&wexFuelCardAccountId=${accountId}&query=${query}&page=1&offset=0&limit=50`;

    return fetch(url, options).then(response => response.json());
}

export function getFleetCorFuelCards(accountId: string, query: string = '') {
    const options = {
        headers: {
            'Accept-Encoding': 'gzip, deflate',
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetFleetCorFuelCardService/getUnassignedFuelCards`;
    url = `${url}?_dc=${randVal}&fleetCorAccountCode=${accountId}&query=${query}&page=1&offset=0&limit=50`;

    return fetch(url, options).then(response => response.json());
}

export function getOdometerAdjustmentHistory(assetId: number) {
    const options = {
        headers: {
            'Accept-Encoding': 'gzip, deflate',
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetAssetAdjustmentService/getOdometerAdjustmentHistory`;
    url = `${url}?_dc=${randVal}&id=${assetId}&page=1&start=0&limit=50`;

    return fetch(url, options).then(response => response.json());
}

export function getEngineTimeAdjustmentHistory(assetId: number) {
    const options = {
        headers: {
            'Accept-Encoding': 'gzip, deflate',
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetAssetAdjustmentService/getEngineTimeAdjustmentHistory`;
    url = `${url}?_dc=${randVal}&id=${assetId}&page=1&start=0&limit=50`;

    return fetch(url, options).then(response => response.json());
}

export function getDeviceSerial(offset: number, limit: number, query: string) {
    const options = {
        headers: {
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/rest/json/device`;
    url = `${url}?_dc=${randVal}&includeAssetName=true&serialNumber=${query}&unassignedOnly=true&offset=${offset}&max=${limit}&page=1&sorts=%5B%7B%22property%22%3A%22serialNumber%22%2C%22direction%22%3A%22ASC%22%7D%5D`;
    return fetch(url, options).then(response => response.json());
}

export const safeParseJSON = (str) => {
    try {
        return JSON.parse(str);
    } catch (error) {
        return null;
    }
};

export function getStates(payload = {}) {
    const dc = new Date().getTime();
    const baseUrl = `${getGrailsAppBasedURL()}/rest/json/referenceState?_dc=${dc}`;

    const params = {
        query: payload.query || '',
        page: payload.page || 1,
        start: payload.start || 0,
        limit: payload.limit || 100,
    };

    return axios.get(baseUrl, {
        headers: {
            Authorization: getAuthorizationHeader(),
        },
        params,
    })
    .then(response => response.data)
    .catch(error => {
        console.error('API Request Failed:', error);
        return null;
    });
}

export function getDrivers(offset: number, limit: number, query: string) {
    const options = {
        headers: {
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetOperatorService/getOperators`;
    url = `${url}?_dc=${randVal}&query=${query}&offset=${offset}&max=${limit}`;
    return fetch(url, options).then(response => response.json());
}

export function getInputs(offset: number, limit: number, query: string) {
    const options = {
        headers: {
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/rest/json/customDeviceInputName`;
    url = `${url}?_dc=${randVal}&query=${query}&offset=${offset}&max=${limit}&filter=%5B%7B%7D%5D`;
    return fetch(url, options).then(response => response.json());
}

export function getAttributeType(){
    const options = {
        headers: {
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    const sort = '[{property:"displayOrder",direction:"ASC"}]';
    const filter = '[{value:"Asset",property:"referenceType"},{value:true,property:"active"}]';
    let url = `${getGrailsAppBasedURL()}/rest/json/attributeType`;
    url = `${url}?_dc=${randVal}&sorts=${escape(sort)}&page=1&offset=0&max=50&filter=${escape(filter)}`;
    return fetch(url, options).then(response => response.json());
}

export function getAssetTypes() {
    const options = {
        headers: {
            Authorization: getAuthorizationHeader(),
        },
    };
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/rest/json/assetType`;
    url = `${url}?_dc=${randVal}&sorts=%5B%7B%22property%22%3A%22name%22%2C%22direction%22%3A%22ASC%22%7D%5D&page=1&offset=0&max=50&filter=%5B%7B%22value%22%3Atrue%2C%22property%22%3A%22userSelectable%22%7D%2C%7B%22value%22%3A%22Asset%22%2C%22property%22%3A%22subtype%22%7D%5D`;;
    return fetch(url, options).then(response => response.json());
}

export function editAsset(content: any) {
    const options = {
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
        },
        body: JSON.stringify(content),
    }
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetAssetService/updateAsset/${encodeURIComponent(content.id)}`;
    url = `${url}?_dc=${randVal}`;
    return fetch(url, options).then(response => response.json());
}

export function addAsset(content: any) {
    const options = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
        },
        body: JSON.stringify(content),
    }
    const randVal = new Date().getTime();
    let url = `${getGrailsAppBasedURL()}/operation/json/localFleetAssetService/saveAsset`;
    url = `${url}?_dc=${randVal}`;
    return fetch(url, options).then(response => response.json());
}

export function addFuelTransaction(content: any) {
    const url = `${config.get('FLEET_SERVICES_URL')}/api/fuel-transactions`;

    return axios.post(url, content, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
        },
        params: { _dc: new Date().getTime() },
    }).then(response => ({
        responseStatus: response.status === 201,
        errorBody: null,
    })).catch(error => ({
        responseStatus: false,
        errorBody: error.response?.data || { errorMessage: "Network error or invalid response" },
    }));
}

export function driverKeyFob(urlConfig: any) {
    const options = {
        method: 'get',
        headers: {
            'X-Nspire-UserToken': getUserToken(),
            'Content-Type': 'application/json',
        },
    }
    const randVal = new Date().getTime();
    let url = `${config.get('PLATFORM_API_BASE_URL')}/keyFobs?assigned=false&_dc=${randVal}`;
    url = `${url}&${querystring.stringify(urlConfig)}`;
    return fetch(url, options).then(response => response.json());
}

export const getDriverDropDownDates = (range: string) => {
    const dates = {
        startDate: moment().startOf('day').add(-1, 'month'),
        endDate: moment().endOf('day').add(-1, 'd'),
    };
    switch (range) {
    case 'yesterday': dates.startDate = moment().startOf('day').add(-1, 'd');
        dates.endDate = moment().endOf('day').add(-1, 'd');
        break;
    case '7': dates.startDate = moment().startOf('day').add(-7, 'd');
        break;
    case '30': dates.startDate = moment().startOf('day').add(-30, 'd');
        break;
    case 'lastMonth': dates.startDate = moment().subtract(1, 'month').startOf('month');
        dates.endDate = moment().subtract(1, 'month').endOf('month');
        break;
    case '90': dates.startDate = moment().subtract(3, 'month').startOf('month');
        dates.endDate = moment().subtract(1, 'month').endOf('month');
        break;
    case '365': dates.startDate = moment().subtract(1, 'year').startOf('year');
        dates.endDate = moment().subtract(1, 'year').endOf('year');
        break;
    case '396': dates.startDate = moment().subtract(13, 'month').startOf('month');
        dates.endDate = moment().subtract(1, 'month').endOf('month');
        break;
    default: break;
    }

    dates.startDate = dates.startDate.format('YYYY-MM-DDTHH:mm:ss');
    dates.endDate = dates.endDate.format('YYYY-MM-DDTHH:mm:ss');
    return dates;
}

export const getAlertFilterDates = (range: string) => {
    const dates = {
        startDate: moment().startOf('day').add(-7, 'd'),
        endDate: moment().endOf('day'),
    };
    switch (range) {
        case 'today': dates.startDate = moment().startOf('day');
            dates.endDate = moment().endOf('day');
            break;
        case 'yesterday': dates.startDate = moment().startOf('day').add(-1, 'd');
            dates.endDate = moment().endOf('day').add(-1, 'd');
            break;
        case '3': dates.startDate = moment().startOf('day').add(-4, 'd');
            dates.endDate = moment().endOf('day').add(-1, 'd');
            break;
        case '7': dates.startDate = moment().startOf('day').add(-7, 'd');
            dates.endDate = moment().endOf('day');
            break;
        case 'last7days': dates.startDate = moment().startOf('day').add(-8, 'd');
            dates.endDate = moment().endOf('day').add(-1, 'd');
            break;
        default: break;
    }
    dates.startDate = moment.utc(dates.startDate).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    dates.endDate = moment.utc(dates.endDate).format('YYYY-MM-DD[T]HH:mm:ss[Z]');
    return dates;
}

export const getContentType = (type: string) => {
    let contentType = 'application/json';
    switch(type) {
    case 'csv': contentType = 'text/csv';
        break;
    case 'xls': contentType = 'application/vnd.ms-excel';
        break;
    case 'xlsx': contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        break;
    case 'pdf': contentType = 'application/pdf';
        break;
    default: break;
    }
    return contentType;
}

export const getHeaderKeys = (data: any) => {
    if (!data && !Array.isArray(data)) return [];
    const removedHiddenColumn = data.filter((d) => d.show);
    return removedHiddenColumn.map((d) => {
        const keys = Object.keys(d);
        if (keys[0] && typeof d[keys[0]] === 'object' && keys.indexOf('reportColumnTitle')) {
            return `${keys[0]}%7C${d[keys[1]]}`;
        } else {
            return `${keys[0]}%7C${d[keys[0]]}`;
        }
    });
};

export const getHeaderKeysForPost = (data: any) => {
    if (!data && !Array.isArray(data)) return [];
    const removedHiddenColumn = data.filter((d) => d.show);
    return removedHiddenColumn.map((d) => {
        const keys = Object.keys(d);
    if (keys[0] && typeof d[keys[0]] === 'object' && keys.indexOf('reportColumnTitle')) {
        return [keys[0],d[keys[1]]];
    } else {
        return [keys[0],d[keys[0]]];
    }
});
};

export const downloadFile = (url: string, fileName: string, option: Object, callback?: Function, body?: Object) => {
    const options = {
        method: 'get',
        headers: {
            'X-Nspire-UserToken': getIframeUserToken() || '',
            'Content-Type': getContentType(option.type),
            ...option.headers,
        },
        body: ''
    };
    if (body) {
        options.method = 'post';
        options.body = JSON.stringify(body);
        options.headers =  {
            'X-Nspire-UserToken': getIframeUserToken() || '',
            'Content-Type': 'application/json',
            'reportType': option.type,
            ...option.headers,
        }
    } else {
        delete options.body;
    }
    return fetch(url, options).
    then((resp) => {
        return resp.blob();
      }).then(function(blob) {
        if (callback && typeof callback === 'function') callback(null, null);
        download(blob, fileName, blob.type);
      }).catch((error) => {
        if (callback && typeof callback === 'function') callback(error, null);
      });
};

export function convertDateTime(timestamp: string, dateFormat: string = '', isTimeZone: boolean = true) {
    const date = moment.utc(timestamp);
    const userSettingsJson = getAppUserSettings() || null;
    const timeFormat = (userSettingsJson && userSettingsJson['app.settings'] && userSettingsJson['app.settings'].value && userSettingsJson['app.settings'].value.timeDisplay) ? userSettingsJson['app.settings'].value.timeDisplay : '12h';
    if(dateFormat === '') {
        dateFormat = (timeFormat === '12h') ? 'MM/DD/YYYY h:mm A' : 'MM/DD/YYYY H:mm';
    }
    if (isTimeZone) {
        const timeZone = (userSettingsJson && userSettingsJson.userTz) ? userSettingsJson.userTz : moment.tz.guess();
        return moment(date).tz(timeZone).format(dateFormat);
    } else {
        return moment(date).format(dateFormat);
    }
}

export function getUnixTimeStamp(dateTime: string) {
    return moment(dateTime).unix();
}

export function mngClmnBasedonSettings(driverSettings: Object, columnName: string) {
    if (driverSettings && driverSettings.settings) {
        return driverSettings.settings[columnName];
    }
    return true;
}

export const getUserSettingTimeZone = () => {
    let timeZone  = moment.tz.guess();
    const userSettings = getAppUserSettings();
    if (userSettings.userTz) {
        if (userSettings.userTz !== '') {
            timeZone = userSettings.userTz;
        }
    }
    return timeZone;
}

export function convertTimeTo24HoursFormat(time: any) {
    if (!time) return '';
    var hrs = Number(time.match(/^(\d+)/)[1]);
    var mnts = Number(time.match(/:(\d+)/)[1]);
    var format = time.match(/\s(.*)$/)[1];
    if (format == 'PM' && hrs < 12) hrs = hrs + 12;
    if (format == 'AM' && hrs == 12) hrs = hrs - 12;
    var hours = hrs.toString();
    var minutes = mnts.toString();
    if (hrs < 10) hours = '0' + hours;
    if (mnts < 10) minutes = '0' + minutes;
    return hours + ':' + minutes;
}

export const normalizePhoneNumber = (phone: string) => {
    if(!phone) return phone;
    phone = phone.replace(/[^\d]/g, '');
    if (phone.length == 10) {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return phone;
}

export function manageLocStateData(locStateData: Object, action: string = 'get') {
    if (locStateData !== undefined) {
        if (action === 'get') {
            return locStateData.fromLocationState ? JSON.parse(getLocalStorageValue(locStateData.fromLocationState) || '{}') : {};
        } else {
            if (locStateData.fromLocationState) {
                removeLocalStorageItem(locStateData.fromLocationState);
            }
            return {};
        }
    } else {
        return {};
    }
}

export function getMilesDrivenOrScore(d: Object, re: string = 'score') {
    let scoreToReturn = d.score;
    if ((!d.score && d.score !== 0) || d.score > 100) {
        scoreToReturn = 'N/A';
    }
    if (!d.milesDriven && d.milesDriven !== 0) {
        if (re === 'score') {
            return scoreToReturn;
        }
        return 'N/A';
    }
    const roundedMilesDriven = parseFloat((d.milesDriven).toFixed(1));
    if (d.eps10To20 === 0 && d.eps21To30 === 0 && d.epsGt30 === 0 && d.est71To80 === 0
        && d.est81To90 === 0 && d.estGt90 === 0 && d.hardAccel === 0
        && d.hardBrake === 0 && d.idle6To18 === 0 && d.idle18To30 === 0
        && d.idleGt30 === 0 && d.seatBelt === 0) {
        if (d.milesDriven === 0) {
            if (re === 'milesDriven') {
                return 'N/A';
            }
        } else if (roundedMilesDriven === 0 && parseFloat(d.milesDriven) > 0) {
            if (re === 'score') {
                if (!d.score || d.score > 100 ) {
                    return 'N/A';
                }
            } else if (re === 'milesDriven') {
                return (1).toFixed(1);
            }
        }
    } else if (d.milesDriven === 0 && (d.idle6To18 > 0 || d.idle18To30 > 0 || d.idleGt30 > 0)) {
        if (re === 'milesDriven') {
            return (0).toFixed(1);
        }
    }

    if (d.milesDriven === 0) {
        if (re === 'milesDriven') {
            return 'N/A';
        }
    } else if (roundedMilesDriven === 0 && parseFloat(d.milesDriven) > 0) {
        if (re === 'milesDriven') {
            return (1).toFixed(1);
        }
        return 0;
    }

    if (re === 'score') {
        return scoreToReturn;
    } else if (re === 'milesDriven') {
        return (d.milesDriven ?convertUnitValueAsPerUserSetting(parseFloat(d.milesDriven)) : 0).toFixed(1);
    }
    return 0;
}

export function checkEnabledFeatures(feature: string, valToCheck: string) {
    let featureEnabled = false;
    const userSettings = getAppUserSettings();
    if (!isEmpty(userSettings)) {
        if (userSettings.featureSet && userSettings.featureSet.length > 0) {
            userSettings.featureSet.some((f) => {
                if (f.category === feature && f.value === valToCheck) {
                    featureEnabled = true;
                    return true;
                }
            });
        }
    }
    return featureEnabled;
}

export function savePage(config: Object) {
    const serializedConfig = JSON.stringify(config);
    setSessionStorageItem('custom_back_config', serializedConfig);
}

export function getSavedPage() {
    const config = getSessionStorageItem('custom_back_config');
    if (config) {
        return JSON.parse(config);
    }
    return null;
}

export function removeSavedPage() {
    removeSessionStorageItem('custom_back_config');
    removeSessionStorageItem('map_zoom_and_bound_details');
    removeSessionStorageItem('map_selected_cluster');
}

export const getValueFromObjectByKey = (data: any, key: string, defaultValue: any) => {
    return data && data[key] ? data[key] : defaultValue;
}

export function getUserPreference(key: string, token: string = '') {
    const options = {
        headers: {
            'X-Nspire-UserToken': token ? token : getUserTokenValue(),
        },
    };
    const url = `${config.get('PLATFORM_API_BASE_URL')}/preferences/fleetLocate${key ? `/${key}` : ''}`;
    return fetch(url, options).then((response) => {
        if (response.ok) {
            return response.json();
        }
        return null;
    });
}

export function getData(url: string, optionsData: any) {
    const options = {
        headers: {
            'X-Nspire-UserToken': getUserToken(),
        },
        ...optionsData,
    };
    return fetch(url, options).then((response) => {
        if (response.ok) {
            return response.json();
        }
        return null;
    });
}

export const postData = (url: string, body: any, optionsData: any) => {
    const options = {
        method: 'post',
        headers: {
            'X-Nspire-UserToken': getUserToken() || '',
            ...optionsData,
        },
        body,
    };
    return fetch(url, options).then((res) => {
        if (res.ok) return res.clone().json();
        return res.clone().json().then(err => { throw err });
    }).catch(err => { throw err });
}

export const putData = (url: string, body: any, optionsData: any) => {
    const options = {
        method: 'put',
        headers: {
            'X-Nspire-UserToken': getUserToken() || '',
            ...optionsData,
        },
        body,
    };
    return fetch(url, options).then((res) => {
        if (res.ok) return res.clone().json();
        return res.clone().json().then(err => { throw err });
    }).catch(err => { throw err });
}

export function saveUserPreference(key: string, data: any) {
    const requestBody = {
        namespace: 'fleetLocate',
        key,
        value: key === SCORECARD_PREF_KEY ? data.config : JSON.stringify(data.config),
    };
    const method = data.method;
    const options = {
        method,
        body: JSON.stringify(requestBody),
        headers: {
            'X-Nspire-UserToken': getUserTokenValue(),
            'Content-Type': 'application/json',
        },
    };
    let url = `${config.get('PLATFORM_API_BASE_URL')}/preferences`;
    if (method === 'put') {
        url = `${config.get('PLATFORM_API_BASE_URL')}/preferences/fleetLocate/${key}`;
    }
    return fetch(url, options).then(response => response.json());
}

export function deleteUserPrefernece(key: string) {
    const options = {
        method: 'delete',
        headers: {
            'X-Nspire-UserToken': getUserToken(),
        },
    };
    const url = `${config.get('PLATFORM_API_BASE_URL')}/preferences/fleetLocate/${key}`;
    return fetch(url, options).then((response) => {
        if (response.ok) {
            return response;
        }
        return null;
    });
}

export const copyTextToClipboard = (str: string) => {
    const el = document.createElement('textarea');
    el.style.cssText = 'display: none, width: 0px';
    el.value = str;
    // $FlowFixMe
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    // $FlowFixMe
    document.body.removeChild(el);
}

export const getAssetStatusStartDate = (item: Object) => {
    let dateTime = '';
    if (item.statusDurationInSeconds) {
        dateTime = getFormattedTimeDifference(item.statusDurationInSeconds * 1000);
    }
    return dateTime;
};

export const getAssetStatusDisplayDate = (item: Object) => {
    const dateFormat = getTimeFormatAsperUserSettting() === '12h' ? 'MM/DD/YYYY h:mm A' : 'MM/DD/YYYY H:mm';
    if (item.statusDisplayDate) {
        return convertDateTime(item.statusDisplayDate, dateFormat);
    }
    return '';
}

export const setCustomAssetStatusStartDate = (statusStartDate: any) => {
    if (statusStartDate === '') return '';
    else  return ` for ${statusStartDate}`;
};

export const getParentGroupsRelation = (data: any, parentId: number = -1, superParent: string = '') => {
    if (!data) return [];
    const returnData = [];
    data.forEach((d) => {
        const isRecoredFound = returnData.some(rd => rd.id === d.id);
        if (!isRecoredFound) {
            returnData.push({
                parentId,
                superParent: superParent || d.name,
                id: d.id,
                name: d.name,
            });
            if (d.children && d.children.length > 0) {
                returnData
                    .push(...getParentGroupsRelation(
                        d.children, d.id,
                        superParent || d.name,
                    ));
            }
        }
    });
    return returnData;
};

export const formatAddress = (data: any) => {
    const address = {
        home: '',
        postal_code: '',
        street: '',
        region: '',
        city: '',
        country: '',
    };
    const shouldBeComponent = {
        home: ['street_number'],
        postal_code: ['postal_code'],
        street: [
            'street_address',
            'route',
            'administrative_area_level_2',
            'administrative_area_level_3',
            'administrative_area_level_4',
            'administrative_area_level_5',
            'neighbourhood',
            'neighborhood',
            'postal_town',
            'premise',
        ],
        region: [
            'administrative_area_level_1',
        ],
        city: [
            'locality',
            'sublocality',
            'sublocality_level_1',
            'sublocality_level_2',
            'sublocality_level_3',
            'sublocality_level_4',
            'establishment',
            'political',
        ],
        country: ['country']
    };
    data.address_components.forEach((component) => {
        for (const shouldBe in shouldBeComponent) {

            if (shouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                if (shouldBe === 'country' || shouldBe === 'region') {
                    address[shouldBe] = component.short_name;
                } else {
                    address[shouldBe] = appendAddressString(address[shouldBe], component.long_name);
                }
            }
        }
    });

    return address;
}

export const appendAddressString = (preAddress : string, address : string) => {
    if (preAddress.length === 0) {
        return address;
    }
    return preAddress + ', ' + address
}

export const getAddressLine1 = (address : any) => {
    let addressLine1 = address && address.home ? address.home : '';
    if (addressLine1.length > 0) {
        addressLine1 = address && address.street ? `${addressLine1} ${address.street}` : addressLine1;
    } else {
        addressLine1 = address && address.street ? address.street : '';
    }
    return addressLine1;
}

export const getShortAddressLine1 = (address : any) => {
    let addressLine1 = address && address.home ? address.home : '';
    if (addressLine1.length > 0) {
        addressLine1 = address && address.street ? `${addressLine1} ${address.street.substr(0, address.street.indexOf(','))}` : addressLine1;
    } else {
        addressLine1 = address && address.street ? address.street.substr(0, address.street.indexOf(',')) : '';
    }
    return addressLine1;
};

export const getAddressLine2 = (address: any) => {
    let addressLine2 = address && address.city ? address.city : '';
    if (addressLine2.length > 0) {
        addressLine2 = address && address.region ? `${addressLine2}, ${address.region}` : addressLine2;
    } else {
        addressLine2 = address && address.region ? address.region : '';
    }
    if (addressLine2.length > 0) {
        addressLine2 = address && address.postal_code ? `${addressLine2} ${address.postal_code}` : addressLine2;
    } else {
        addressLine2 = address && address.postal_code ? address.postal_code : '';
    }
    return addressLine2;
}

export const getUnitsOfMeasurments = () => {
    return [
        {
            value: 'miles',
            title: 'English',
        },
        {
            value: 'meters',
            title: 'Metric',
        },
    ];
}

export const getTimeDisplays = () => {
    return [
        {
            value: '12h',
            title: 'AM/PM',
        },
        {
            value: '24h',
            title: 'Military',
        },
    ];
}

export const getDefaultPages = () => {
    const pages = [];
    if (getPermissionValue('Map') !== 'None') {
        pages.push({value: 'map', title: 'Map'});
    }
    if (getPermissionValue('Alerts') !== 'None') {
        pages.push({value: 'alerts', title: 'Alerts'});
    }
    if (getPermissionValue('Dashboard') !== 'None') {
        pages.push({value: 'dashboard', title: 'Dashboard'});
    }
    if (getPermissionValue('Reports') !== 'None') {
        pages.push({value: 'reports', title: 'Reports'});
    }
    if (getPermissionValue('Admin') !== 'None') {
        pages.push({value: 'users', title: 'Admin'});
    }
    return pages;
}

export const getAssetTypeCodeProperty = (id: any, assetTypes: any) => {
    let assetTypeLabelProperty = '';
    assetTypes.forEach((assetType) => {
        if(assetType.value === parseInt(id, 10) || null) {
            assetTypeLabelProperty = assetType.code;
        }
    });
    return assetTypeLabelProperty;
}

export const isReseller = () => {
    if (window.location.hostname.toLowerCase().indexOf('gpsservicenetwork') > -1){
        return true; //change this to false, to disable reseller features
    }
    return false;
}

export const getResellerInfo = () => {
    return resellerInfo;
}

export const updateResellerInfo = (data: any) => {
    if (isReseller()) {
        const { brandedTabTitle = '', appLogo = '' } = data;
        resellerInfo = {
            appLogo,
            brandedTabTitle,
        }
        document.title = brandedTabTitle;
    } else {
        document.title = 'Fleet Locate';
    }
}

export const handleSpudNotification = (action: string = 'get', data: Object = {}) => {
    let dataToReturn = '';
    if (action === 'set' && data.notificationBannerText) setLocalStorageItem('spudN', data.notificationBannerText);
    else if (action === 'get') dataToReturn = getLocalStorageValue('spudN');
    return dataToReturn;
}

export const getAppToken = () => {
    if (isReseller()) {
        return config.get('RESELLER_APP_TOKEN');
    }
    return config.get('REACT_APP_APP_TOKEN');
}

export const isAcceptedTermsAndConditions = () => {
    if (!isEmpty(termsAndConditions) && termsAndConditions.accepted) {
        return true; //accepted === true then enable ssa.
    } else if (!isEmpty(termsAndConditions) && termsAndConditions.canAccept) {
        return false; //canAccept === true then take to sign ssa page.
    }
    return false;
}

export const setTermsAndConditions = (resp: any) => {
        return new Promise((resolve, reject) => {
            if (resp.content && resp.content[0]) {
                termsAndConditions = resp.content[0];
                if (!isEmpty(termsAndConditions) && termsAndConditions.accepted){
                    return resolve();
                } else if (!isEmpty(termsAndConditions) && termsAndConditions.canAccept){
                    return resolve();
                }
                return reject();
            }
            return reject();
        });
    }

export const getTermsAndConditions = () => {
    return termsAndConditions;
}

export function getGrailsAppBasedURL() {
    return isReseller() ? config.get('GRAILS_APP_BASE_RESELLER_URL') : config.get('GRAILS_APP_BASE_URL');
}

export function getCalibrationURL() {
    return isReseller() ? config.get('DASHCAM_CALIBRATION_RESELLER_URL') : config.get('DASHCAM_CALIBRATION_URL');
}

export function getComplianceBasedURL() {
    return isReseller() ? config.get('COMPLIANCE_BASE_RESELLER_URL') : config.get('COMPLIANCE_BASE_URL');
}

export function getAllowedCookieDomain() {
    return isReseller() ? PRIVATE_LABEL_COOKIE_DOMAIN : SPIREON_COOKIE_DOMAIN;
}

export const setCookie = (cookie: string, key: string) => {
    Cookies.set(key, cookie, { expires: CONSTANTS.COOKIE_EXPIRATION_TIME_IN_DAYS, domain: getAllowedCookieDomain() });
};

export const getCookie = (key: string) => {
    return Cookies.get(key) ? true : false;
};

export const getScorecardSettingsText = (grpKey:string, dataKey:string) => {
    const settings = settingData.find(e => e.grpKey === grpKey);
    if (settings && settings.data) {
        const data = settings.data.find(e => e.settKey === dataKey);
        if (getUserUnit() === 'metric') {
            if (data && data.settDTextInKm) {
                return data.settDTextInKm;
            }
        }
        if (data && data.settDText) {
            return data.settDText;
        }
    }
    return '';
}

export const removeMultipleSpacesBetweenChars = (input: string) => {
    return input.replace(/\s+(?=\s)/g, '');
}

export const formatInstallationTest = (str: string = '') => {
    if (str) {
        let strng = str.split('_').join(' ').toLowerCase();
        if(strng.startsWith('test ')) {
            strng = strng.replace('test ', '');
        }
       return capitalizeFirstChar(strng);
    }
    else return '';
}

export const formatLandmarkAddress = (data: Object) => {
    const address = [];
    if (data.address) {
        address.push(data.address);
    }
    if (data.city) {
        address.push(data.city);
    }
    if (data.state || data.zipCode || data.zip) {
        address.push(`${data.state ? `${data.state} ` : ''}${data.zipCode || data.zip}`);
    }
    return address.join(', ');
};

export const formatInstallationAddress = (data: Object) => {
    const address = [];
    if (data.addrLine1) {
        address.push(data.addrLine1);
    }
    if (data.city) {
        address.push(data.city);
    }
    if (data.state || data.postalCode) {
        address.push(`${data.state ? `${data.state} ` : ''}${data.postalCode}`);
    }
    return address.join(', ');
};

export const isIframe = () => {
    return window.location.href.toLowerCase().indexOf('iframe/') !== -1;
};

export const isPairedAsset = (asset: Object) => !!asset.deviceSerialNumber;

export const getSnapRoute = () => getLocalStorageValue('snapRoutes') || 'false';

export const setTripData = (tripIdWithIndex: string, data: any, eventCount: number) => {
    tripDataStore.eventCount += eventCount;
    if (tripDataStore.eventCount > TRIP_DIRECTIONS_CACHE_LIMIT) {
        const diff = tripDataStore.eventCount - TRIP_DIRECTIONS_CACHE_LIMIT;
        let eCount = 0;
        const needToDeleteKey = [];
        Object.keys(tripDataStore).some((k) => {
            if (k !== 'eventCount' && tripDataStore[k]) {
                eCount += tripDataStore[k].eventCount;
                needToDeleteKey.push(k);
                if (eCount >= diff) return true;
            }
            return false;
        });
        needToDeleteKey.forEach(k => {
            if (tripDataStore[k]) {
                tripDataStore.eventCount -= tripDataStore[k].eventCount;
                delete tripDataStore[k]
            }
        });
    }
    tripDataStore[tripIdWithIndex] = { data, eventCount };
}

export const getTripData = (tripIdWithIndex: string) => ((tripDataStore[tripIdWithIndex]) ?
    tripDataStore[tripIdWithIndex].data :
    null
)

export const isAssetNonReporting = (asset: Object) =>  asset ? (asset.reportingStatus || '').toLowerCase() === 'non-reporting' : false;

export const getNonReportingToolTip = (asset: Object) => {
    let toolTip = 'Never Reported';
    if (asset.lastEventDate) {
        switch(asset.status) {
            case 'Moving': toolTip = `Not reporting for > ${NON_REPORTING_DAYS.Moving} day`;
                            break;
            case 'Idle': toolTip = `Not reporting for > ${NON_REPORTING_DAYS.Idle} day`;
                            break;
            default: toolTip = `Not reporting for > ${NON_REPORTING_DAYS.Stopped} days`;
                            break;
        };
    }
    return toolTip;
};


const buildValueObject = (yDiff, mDiff, dDiff, hourDiff, minDiff, secDiff, firstDateWasLater, isSameDate) => ({
        "years"   : yDiff,
        "months"  : mDiff,
        "days"    : dDiff,
        "hours"   : hourDiff,
        "minutes" : minDiff,
        "seconds" : secDiff,
        "firstDateWasLater" : firstDateWasLater,
        "isSameDate": isSameDate
});

export const getTimeDiff = (d1: any, d2: any) => {
        let fromDate = moment(convertDateTime(d1, 'YYYY-MM-DD HH:mm:ss'));
        let toDate = moment(convertDateTime(d2, 'YYYY-MM-DD HH:mm:ss'));
        let firstDateWasLater = false;

        if (fromDate.isSame(toDate)) return buildValueObject(0, 0, 0, 0, 0, 0, false, true);

        if (fromDate.isAfter(toDate)) {
            const tmp = fromDate;
            fromDate = toDate;
            toDate = tmp;
            firstDateWasLater = true;
        } else firstDateWasLater = false;

        const yDiff = toDate.diff(fromDate, 'year');
        toDate.subtract(yDiff, 'years');
        const mDiff = toDate.diff(fromDate, 'months');
        toDate.subtract(mDiff, 'months');
        const dDiff = toDate.diff(fromDate, 'days');
        toDate.subtract(dDiff, 'days');
        const hourDiff = toDate.diff(fromDate, 'hours');
        toDate.subtract(hourDiff, 'hours');
        const minDiff = toDate.diff(fromDate, 'minutes');
        toDate.subtract(minDiff, 'minutes');
        const secDiff = toDate.diff(fromDate, 'seconds');

    return buildValueObject(yDiff, mDiff, dDiff, hourDiff, minDiff, secDiff, firstDateWasLater, false);
}

export const getTimeStr = (timeObject: Object) => {
    const result = [];
    let timeStr = '';

    if (timeObject.years) result.push(`${timeObject.years}y`);
    if (timeObject.months) result.push(`${timeObject.months}mo`);
    if (timeObject.days) result.push(`${timeObject.days}d`);
    if (timeObject.hours) result.push(`${timeObject.hours}h`);
    if (timeObject.minutes) result.push(`${timeObject.minutes}m`);

    if (result.length > 0) {
        timeStr = result.join(' ');
    } else if (!timeObject.isSameDate) {
        timeStr = '<1m';
    }

    return timeStr;
}

export const getResellerData = () => {
    getData(
        `${getGrailsAppBasedURL()}/home/getStartupData`,
        { headers: { Authorization: getAuthorizationHeader(), } },
    )
        .then((result: any) => {
            updateResellerInfo(result);
        });
}

export const updateCustomerContact = (userAccounts: Array<Object>) => {
    const {CUSTOMER_SUPPORT_BRAND_LIST, CUSTOMER_SUPPORT_DETAILS} = CONSTANTS;
    const customerContactInfo = {};

    if(userAccounts && userAccounts.length > 0) {
        CUSTOMER_SUPPORT_BRAND_LIST.forEach(b => {
            userAccounts.forEach(a => {
                if (a.intermediaryRef && a.intermediaryRef.name && a.intermediaryRef.name.toLowerCase() === b.toLowerCase()) {
                    customerContactInfo[a.id] = CUSTOMER_SUPPORT_DETAILS[a.intermediaryRef.name];
                } else {
                    customerContactInfo[a.id] = CUSTOMER_SUPPORT_DETAILS.fleetSupport;
                }
            });
        });
    };
    setLocalStorageItem('customerSupport', JSON.stringify(customerContactInfo));
}

export const getCustomerContact = (selectedAccount: string) => {
    const customerSupportInfo = JSON.parse(getLocalStorageValue('customerSupport') || '{}');
    let customerSupportInfoValue = customerSupportInfo[Object.keys(customerSupportInfo)[0]];
    if (selectedAccount) {
        customerSupportInfoValue = customerSupportInfo[selectedAccount];
    }
    return customerSupportInfoValue || CONSTANTS.CUSTOMER_SUPPORT_DETAILS.fleetSupport;
}

export const saveUserPreferenceForDriver = (key: string, path: string) => {
    getUserPreference(key).then((response) => {
        const driverPreference = response && response.value ? response.value : null;
        let driverPreferenceMethod = 'post';
        if (driverPreference) {
            driverPreferenceMethod = 'put';
        }

        saveUserPreference(key, {
            config: path,
            method: driverPreferenceMethod,
        });
    });
}

export const blockLoginDueToInstallAppPermission = (userSettings: Object) => {
    let blockLogin = false;
    const { permissions } = userSettings;
    if (permissions && permissions.length > 0) {
        const hasInstallAppPermission = permissions.some((permission) => permission.permissionCode === 'localFleet-install-app' && permission.option.toLowerCase() !== 'none');
        const hasOtherPermission = permissions.some((permission) => permission.permissionCode !== 'localFleet-install-app' && permission.option.toLowerCase() !== 'none');
        
        if (hasInstallAppPermission && !hasOtherPermission) {
            blockLogin = true;
        }
    }
    return blockLogin;
}

export const checkDriverPagePermission = (data: any) => {
    let isDriverPage = false;
    const { userSettings } = data;

    if (!isEmpty(userSettings)) {
        if (userSettings['app.settings'] &&
            userSettings['app.settings'].value &&
            userSettings['app.settings'].value.defaultPage) {
            let pageName = userSettings['app.settings'].value.defaultPage;
            pageName = pageName.toLowerCase().split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join();
            if (getPermissionValue(pageName, data) !== 'None') return isDriverPage;
        }
        const userPermission = userSettings.permissions || [];

        userPermission.sort((a, b) => (a.id > b.id) ? 1 : -1);

        if (typeof userPermission === 'object' ) {
            for (let i = 0; i < userPermission.length; i++) {
                if (userPermission[i].option !== 'None') {
                    const landingPageSection = userPermission[i].permissionName.replace(' Section', '');
                    // for Compliance tab we need to check getPermissionValue
                    if (listTypePermissionMap[landingPageSection] && getPermissionValue(landingPageSection, data) !== 'None') {
                        if (listTypePermissionMap[landingPageSection].landingPage === CONSTANTS.DRIVER_SCORECARD) {
                            return true;
                        } else {
                            isDriverPage = false;
                        }
                    }
                }
            }
        }
    }
    return isDriverPage;
}

export const checkPasswordStrength = (data: string) => {
    if(data && (data.length < PASSWORD_MIN_LENGTH || data.length > FIELD_MAX_LENGTH_255)){
        return false;
    }
    return data && data.match(PASSWORD_REGEX);
}

const getUserTokenValue = () => isIframe() ? getIframeUserToken() : getUserToken();

export const capitalizeFirstChar = (str: string = '') => {
    if (str) return str.charAt(0).toUpperCase() + str.slice(1);
    else return '';
}

export function isSupportedBrowser() {
    let isSupported = true;
    if (window.AdapterJS && window.AdapterJS.webrtcDetectedBrowser) {
        const browser = (window.AdapterJS.webrtcDetectedBrowser || '').toLowerCase();
        switch (browser) {
            case 'edge':
                isSupported = (window.AdapterJS.webrtcDetectedVersion >= CONSTANTS.MINIMUM_SUPPORTED_EDGE_VERSION);
                break;
            case 'ie':
            case 'opera':
                isSupported = false;
                break;
            case 'chrome':
                //Check for edge case: Microsoft Edge reporting as Chrome
                const edge = window.navigator.userAgent.indexOf('Edg') > 0;
                if (edge != null) {
                    isSupported = (window.AdapterJS.webrtcDetectedVersion >= CONSTANTS.MINIMUM_SUPPORTED_EDGE_VERSION);
                } else {
                    isSupported = (window.AdapterJS.webrtcDetectedVersion >= CONSTANTS.MINIMUM_SUPPORTED_CHROME_VERSION);
                }
                break;
            case 'firefox':
                isSupported = (window.AdapterJS.webrtcDetectedVersion >= CONSTANTS.MINIMUM_SUPPORTED_FIREFOX_VERSION);
                break;
            case 'safari':
                isSupported = (window.AdapterJS.webrtcDetectedVersion >= CONSTANTS.MINIMUM_SUPPORTED_SAFARI_VERSION);
                break;
            default: break;
        }
    }

    return isSupported;
}

export const getLandmarkName = (location: any) => {
    let name = '';
    const { landmark } = location;
    if (landmark && landmark.name) {
        name = `${(landmark.name)}, `;
    }

    return name;
};

export const removeAuthorizationKeyFromErrorObj = (error: string) => {
    const jsonErrorObj = JSON.parse(JSON.stringify(error || '{}'));
    if (jsonErrorObj !== '{}' && jsonErrorObj.request && jsonErrorObj.request.headers && jsonErrorObj.request.headers.Authorization) {
        delete jsonErrorObj.request.headers.Authorization;
    }
    return jsonErrorObj;
};

export function checkUserSettings() {
    const userSettings = getAppUserSettings();
    return (!isEmpty(userSettings) && !userSettings['app.settings']) || !userSettings.userTz || userSettings['app.settings'].value.timeDisplay === '';
}

export function bulkDeleteLandmark(id: string) {
    const options = {
        method: 'delete',
        headers: {
            'X-Nspire-UserToken': getUserToken(),
        },
    };
    const url = `${config.get('PLATFORM_API_BASE_URL')}/geozones/${id}`;
    return fetch(url, options).then((response) => {
        if (response.ok) {
            return response;
        }
        return null;
    });
}

export function bulkDeleteFuelTransaction(id: string) {
    const options = {
        headers: {
            'X-Nspire-UserToken': getUserToken(),
        },
    };
    const url = `${config.get('FLEET_SERVICES_URL')}/api/fuel-transactions/${id}`;
    return axios.delete(url, options).then((response) => {
        return response;
    }).catch(() => null);
}

export const getDateErrorMessage = (error) => {
    switch (error) {
    case 'invalidDate': {
        return 'Invalid Date format';
    }
    case 'disablePast': {
        return 'Date should not be in past'
    }
    case 'disableFuture': {
        return 'Date should not be in future';
    }
    default: {
        return '';
    }
    }
}

export const getFormattedTimezone = (userTz: string) => (userTz.toLowerCase() === 'pacific/pitcairn' ? 'PST' : moment().tz(userTz).format('z'));

export const lmLogout = () => {
    const lmIframe = document.getElementById('lm-dashcam');
    if (lmIframe) lmIframe.contentWindow.postMessage({ "eventType": "logout" }, '*');
}

export const clearLocalStorageAndCookieOnLogout = () => {
    clearLocalStorage();
    clearCookieOnLogout();
    lmLogout();
}

export const getAuthServiceURL = (path: string) => isReseller() ? `${config.get('RESELLER_AUTH_SERVICE_URL')}/auth/${path}?clientId=fleetPrivateWeb` : `${config.get('FLEET_AUTH_SERVICE_URL')}/auth/${path}?clientId=fleetWeb`;

export const redirectToAuthService = (data: { isError: boolean, errorCode: string, ref?: string }) => {
    let url = getAuthServiceURL('login');
    clearCookieOnLogout();

    if (data.isError && data.errorCode) {
        url = `${url}&authErrorCode=${CONSTANTS.AUTH_ERROR_CODES[data.errorCode]}`
    }

    if(data.ref) {
        url = `${url}&ref=${data.ref}`;
    }
    window.location.href = url;

    return true;
}

export const redirectToAuthServiceChangePassword = () => {
    let url = getAuthServiceURL('changePassword');
    url = `${url}&apiToken=${getUserToken()}`
    window.location.href = url;
    return true;
}

export const isBoolean = val => typeof val === 'boolean';

export const roundOffToDecimalPlaces = (value, decimalPlaces) => {
    if (typeof value === 'number' && typeof decimalPlaces === 'number' && decimalPlaces >= 0) {
        const factor = Math.pow(10, decimalPlaces);
        return Math.round(value * factor) / factor;
    }
};

