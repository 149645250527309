/* @flow */

export const FETCH_FUEL_TRANSACTIONS_LIST = 'FETCH_FUEL_TRANSACTION_LIST';
export const FETCH_FUEL_TRANSACTIONS_LIST_SUCCESS = 'FETCH_FUEL_TRANSACTION_LIST_SUCCESS';
export const FETCH_FUEL_TRANSACTIONS_LIST_ERROR = 'FETCH_FUEL_TRANSACTION_LIST_ERROR';
export const FUEL_TRANSACTION_LIST_PREF_KEY = 'fleetLocate.fuelTransactionList';

export type FuelTransactionListTypeAction = {
    type: string;
    payload? : Object;
}
